import { formContentError, formContentSuccess, formDescription,
  formTitle, formTitleError, formTitleSuccess, spacingBottom, spacingTop, anchor } from '@/utils/meta';
import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';

import Dealers from 'utils/repo/dealers';

class CorporateContactForm implements ContentBlock {
  label = 'Corporate Contact Form';

  component = 'CorporateContactForm';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    anchor,
    formTitle,
    formDescription,
    formTitleSuccess,
    formContentSuccess,
    formTitleError,
    formContentError,
    spacingTop,
    spacingBottom
  ];

  properties = [];

  async adapter(contents: any[], extra: any, dataFetcher: any): Promise<object> {
    const locale = extra?.context?.locale;
    const productId = extra?.context?.productId;

    const dealers = [];
    const dealersByLocation = (await dataFetcher(Dealers.getAllDealersAddressForForm(), { productId: productId }))?.data
    ?.dealers;

    dealersByLocation.forEach((dealer) => {
      dealer.addresses.forEach((address) => {
        const isMainAddress = address.mainAddress;

        const dealerData = {
          id: dealer.id,
          address: { ...address },
          name: isMainAddress ? dealer.name : address.name,
          companyName: dealer.companyName,
          mainAddress: isMainAddress
        };

        dealers.push(dealerData);
      });
    });

    const listStates = [...new Set(dealers.map((state) => state.address.state))].sort();

    return {
      locale: String(locale).toLowerCase(),
      dealers: dealers ,
      states: listStates,
      productId: productId,
      title: extra?.settings?.title,
      formType: extra?.settings?.formType,
      description: extra?.settings?.description,
      titleSuccess: extra?.settings?.titleSuccess,
      contentSuccess: extra?.settings?.contentSuccess,
      titleError: extra?.settings?.titleError,
      contentError: extra?.settings?.contentError,
      spacingTop: extra?.settings?.spacingTop || '0',
      spacingBottom: extra?.settings?.spacingBottom || '0',
    };
  }
}

export { CorporateContactForm };
