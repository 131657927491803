export const phoneNumber = /\([1-9]{2}\) [0-9]{4,5}.[0-9]{4}/;

export const phoneMask = (value) => {
  value = value.replace(/\D/g, ''); //Remove tudo o que não é dígito
  value = value.replace(/^(\d\d)(\d)/g, '($1) $2'); //Coloca parênteses em volta dos dois primeiros dígitos
  value = value.replace(/-/, ''); //Remove todos os hífens
  if (value.length > 13) {
    value = value.replace(/(\d{5})(\d)/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígito
  } else {
    value = value.replace(/(\d{4})(\d)/, '$1-$2'); //Coloca hífen entre o quinto e o sexto dígito
  }
  return value;
};

export function phoneMaskDdg(value) { // Para números 0800
  value = value.replace(/\D/g, ''); //Remove tudo o que não é dígito
  value = value.replace(/-/, ''); //Remove todos os hífens
  value = value.replace(/(\d{7})(\d)/, '$1-$2'); //Coloca hífen entre o sétimo e o oitavo dígito
  value = value.replace(/(\d{4})(\d)/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígito
  return value;
}

export function phoneNoDddMask(value) {
  return value

    .replace(/\D/g, '')

    .replace(/(\d{4})(\d)/, '$1-$2')

    .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')

    .replace(/(-\d{4})\d+?$/, '$1');
}

export function phoneNoDddTasaMask(value) {
  return value
  .replace(/^[0]/g, '')
  .replace(/15\d+?$/g, '');
}

export function dddMask(value) {
  return value.replace(/\D/g, '').replace(/^(\d{2})(\d)/g, '($1)');
}

export function validaNumber(value) {
  return value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
}

export const moneyMask = (value) => {
  // console.log('cheguei com o valor: ', value);
  value = value?.toString();

  value = value.replace(/\D/g, ''); //Remove tudo o que não é dígito
  value = value.replace(/[Ａ-Ｚａ-ｚ]/g, '');
  value = value.replace(/([0-9]{2})$/g, ',$1');

  if (value.length > 6) {
    value = value.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');
  }

  return value;
};
export const isValidCPF = (cpf) => {
  if (typeof cpf !== 'string') {return false;}
  cpf = cpf.replace(/[\s.-]*/gim, '');
  if (
    !cpf ||
    cpf.length != 11 ||
    cpf == '00000000000' ||
    cpf == '11111111111' ||
    cpf == '22222222222' ||
    cpf == '33333333333' ||
    cpf == '44444444444' ||
    cpf == '55555555555' ||
    cpf == '66666666666' ||
    cpf == '77777777777' ||
    cpf == '88888888888' ||
    cpf == '99999999999'
  ) {
    return false;
  }
  let soma = 0;
  let resto;
  for (let i = 1; i <= 9; i++) {
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  }
  resto = (soma * 10) % 11;
  if (resto == 10 || resto == 11) {resto = 0;}
  if (resto != parseInt(cpf.substring(9, 10))) {return false;}
  soma = 0;
  for (let i = 1; i <= 10; i++) {
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  }
  resto = (soma * 10) % 11;
  if (resto == 10 || resto == 11) {resto = 0;}
  if (resto != parseInt(cpf.substring(10, 11))) {return false;}
  return true;
};

export const cpfMask = (value) => {
  value = value.replace(/\D/g, '');

  if (value.length <= 11) {
    value = value.replace(/(\d{3})(\d)/, '$1.$2');
    value = value.replace(/(\d{3})(\d)/, '$1.$2');
    value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  } else {
    value = value.replace(/^(\d{2})(\d)/, '$1.$2');
    value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    value = value.replace(/\.(\d{3})(\d)/, '.$1/$2');
    value = value.replace(/(\d{4})(\d)/, '$1-$2');
  }

  return value;
};

export const cepMask = (value) => {
  return value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2');
};

export const isValidCEP = /\d{5}[-\s]?\d{3}/;

export const isValidResult = (value) => {
  if (value === '15') {
    return true;
  }
  return false;
};

export const dateMask = (value) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1');
};

export function replaceUrl(value) {
  const replaceUrl = value.replace(/^https?:\/\//, '');
  return replaceUrl;
}

export function chassiMask(value) {
  const regex = /([a-zA-Z0-9]{1})([a-zA-Z0-9]{2})([a-zA-Z0-9]{6})([a-zA-Z0-9]{1})([a-zA-Z0-9]{7})/;
  const replaceChassi = value.replace(regex, '$1 $2 $3 $4 $5').toUpperCase();
  return replaceChassi;
}