import { anchor } from 'utils/meta';
import { ContentBlock } from '../content-blocks/ContentBlock';
import { ContentBlockType } from '../content-blocks/ContentBlockType';

class ActionBanner implements ContentBlock {
  label = 'Banner Carousel';

  component = 'ActionBanner';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = true;

  settings = [
    {
      name: 'autoplay',
      label: 'Autoplay',
      help: 'Add autoplay to banner carousel',
      type: 'boolean',
      control: 'toggle',
      defaultValue: true
    },
    anchor
  ];

  properties = [
    {
      name: 'title',
      label: 'Title',
      help: 'Set the card title',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'description',
      label: 'Content',
      help: 'The content of the card',
      type: 'string',
      control: 'textarea',
      defaultValue: '',
      props: null
    },
    {
      name: 'media',
      label: 'Banner desktop image',
      help: 'Set the desktop banner image',
      type: 'media',
      control: 'upload',
      defaultValue: '',
      props: null
    },
    {
      name: 'mediaMob',
      label: 'Banner mobile image',
      help: 'Set the mobile banner image',
      type: 'media',
      control: 'upload',
      defaultValue: '',
      props: null
    },
    {
      name: 'callToActions[].label',
      label: 'Link label',
      help: '',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'callToActions[].target',
      label: 'Link target',
      help: '',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    }
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async adapter(contents: any[], extra: any): Promise<object> {
    return {
      autoplay: extra?.settings?.autoplay,
      sliderData: contents?.map((content) => {
        if (!content?.media) {
          throw Error("Content 'media' are required");
        }

        return {
          title: content?.title || null,
          description: content?.description || null,
          bannerImage: {
            desktop: content?.media?.url || null,
            mobile: content?.mediaMob?.url || null
          },
          cta: content?.callToActions?.[0]?.label
            ? {
              text: content?.callToActions?.[0]?.label || null,
              action: content?.callToActions?.[0]?.target || null
            }
            : null,
          link: content?.callToActions?.[1]?.label
            ? {
              label: content?.callToActions?.[1]?.label || null,
              url: content?.callToActions?.[1]?.target || null
            }
            : null
        };
      })
    };
  }
}

export { ActionBanner };
