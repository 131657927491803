import React from 'react';
import Media from '../media';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Atom ThumbHover
 *
 * <!-- TODO: add a description here! -->
 */
function ThumbHover(props) {
  const { className = '', image, children, ...other } = props;

  const hasValidImage = Array.isArray(image) && image.length > 0 && image[0]?.url;

  return (
    <div className={`${css['molecule__thumb-hover-container']} ${className}`} {...other}>
      {hasValidImage &&
        (image?.[1]?.url ? (
          <>
            <Media
              url={image?.[0]?.url}
              alt={image?.[0]?.alt}
              type={image?.[0]?.type}
              className={css['car-image-base']}
            />
            <Media
              url={image?.[1]?.url}
              alt={image?.[1]?.alt}
              type={image?.[1]?.type}
              className={css['car-image-hover']}
            />
          </>
        ) : (
          <Media url={image?.[0]?.url} alt={image?.[0]?.alt} type={image?.[0]?.type} className={css['hover-grow']} />
        ))}
      {children}
    </div>
  );
}

export default ThumbHover;
