import React from 'react';

/**
 * Atom MissingBlock
 *
 * A simple atom to replace missing components/blocks
 */
function MissingBlock (props) {
  const {
    missingComponent = "",
  } = props;

  return <span data-missing-component={missingComponent} data-visuallyhidden>Could not find block component {missingComponent}</span>;
}

export default MissingBlock;
