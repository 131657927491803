import React, { useState } from 'react';
import AnchorLink from '../../__atoms/anchor-link';
import { IcnClose } from 'components/__icons';
import ThumbHover from '../thumb-hover';

// loading the sass style fot the component
import css from './styles.module.scss';
import { currency } from '../../../utils/functions';

function TabCar(props) {
  const { className = '', carlabel, service, image, prices = null, specs = null, variant = null, locale, ...other } = props;
  const label_id = carlabel?.trim().toLowerCase().split(" ").join("_").replace("/","");
  const service_id = service?.trim().toLowerCase().split(" ").join("_");

  const getPriceByInterval = (interval) => {
    if (prices) {
      const priceIndex = prices.findIndex((price) => price.interval === interval && price.value !== 0);
      return priceIndex !== -1 ? prices[priceIndex].value : null;
    }
    return null;
  };

  const [activeSection, setActiveSection] = useState('default');
  const currencySymbol = locale === 'es-AR' && 'ARS';
  const priceHour = getPriceByInterval('HOUR');
  const priceDay = getPriceByInterval('DAY');

  const monthPrices = buildMonthPrices(prices) as {
    lowestPackage: { value: any; package: any };
    highestPackage: { value: any; package: any };
  };

  const franchise = prices?.find(
    (price) => price.franchise !== undefined && price?.franchise !== 0 && price?.franchise !== '0'
  )?.franchise;
  const airportCharge =
    prices?.find(
      (price) =>
        price?.airportCharge !== undefined &&
        price?.airportCharge !== null &&
        price?.airportCharge !== 0 &&
        price?.airportCharge !== '0'
    )?.airportCharge || null;

  const intervalMapping = {
    HOUR: 'Hora',
    DAY: 'Dia',
    WEEK: 'Semana',
    MONTH: 'Mes'
  };

  const formattedPrices = prices
    ? prices.map((price) => ({
        interval: intervalMapping[price.interval] || '',
        defaultInterval: price?.interval.toLowerCase(),
        value: price.value !== 0 ? price.value : null
      }))
    : [];

  const showCarDetails = () => setActiveSection('detail');
  const showCarPrices = () => setActiveSection('price');
  const closeSection = () => setActiveSection('default');

  const formatToPrice = (price) => {
    return price !== null ? `${currency(price, locale, currencySymbol)}*` : null;
  };

  const shouldShowPriceSection = (prices) =>
    prices && prices.some((price) => price.value > 0 && price.interval !== 'MONTH');

  const renderHourPrice = () => (
    <div className={`${css['car-content-info-left']} ${!priceDay && css['centered']}`}>
      <span>
        {`Hora: `}
        <span className={css['car-content-info-price']} data-testid="car-price-hour">
          {formatToPrice(priceHour)}
        </span>{' '}
      </span>
    </div>
  );

  const renderDayPrice = () => (
    <div className={`${css['car-content-info-right']} ${!priceHour && css['centered']}`}>
      {priceDay && (
        <span>
          {`Día: `}
          <span className={css['car-content-info-price']} data-testid="car-price-day">
            {formatToPrice(priceDay)}
          </span>
        </span>
      )}
    </div>
  );

  const renderMonthPrice = () => (
    <div className={`${css['car-content-info-left']} ${!monthPrices?.highestPackage?.value && css['centered']}`}>
      <span>
        <span className={css['car-content-info-price']} data-testid="car-price-month">
          {formatToPrice(monthPrices?.lowestPackage?.value)}
        </span>
        {` por mes`}
      </span>
      <span
        className={
          css['car-content-info-right-package']
        }>{`(por ${monthPrices?.lowestPackage?.package?.value} meses)`}</span>
    </div>
  );

  const renderDivider = () => <div className={css['car-content-divider']}></div>;

  const renderHighestPackagePrice = () => (
    <div className={`${css['car-content-info-right']} ${!monthPrices?.lowestPackage?.value && css['centered']}`}>
      <span>
        <span className={css['car-content-info-price']} data-testid="car-price-month">
          {formatToPrice(monthPrices?.highestPackage?.value)}
        </span>
        {` por mes`}
      </span>
      <span
        className={
          css['car-content-info-right-package']
        }>{`(por ${monthPrices?.highestPackage?.package?.value} meses)`}</span>
    </div>
  );

  const renderPriceComponents = () => {
    return [
      priceHour && renderHourPrice(),

      priceHour && priceDay && renderDivider(),

      priceDay && renderDayPrice(),

      !priceHour && !priceDay && monthPrices?.lowestPackage?.value && renderMonthPrice(),

      !priceHour &&
        !priceDay &&
        monthPrices?.lowestPackage?.value &&
        monthPrices?.highestPackage?.value &&
        renderDivider(),

      !priceHour && !priceDay && monthPrices?.highestPackage?.value && renderHighestPackagePrice()
    ].filter(Boolean);
  };

  function buildMonthPrices(data) {
    if (!data) {
      return null;
    }

    const monthData = data.filter((item) => item.interval === 'MONTH');

    if (monthData.length === 0) {
      return null;
    }

    if (monthData.length === 1) {
      const monthPrice = monthData[0];
      return {
        lowestPackage: {
          value: monthPrice.value,
          package: monthPrice.package
        },
        highestPackage: null
      };
    }

    const lowestPrice = monthData.reduce((lowest, current) => {
      return current.package.value < lowest.package.value ? current : lowest;
    });

    const highestPrice = monthData.reduce((highest, current) => {
      return current.package.value > highest.package.value ? current : highest;
    });

    const monthPrices = {
      lowestPackage: {
        value: lowestPrice.value,
        package: lowestPrice.package
      },
      highestPackage: {
        value: highestPrice.value,
        package: highestPrice.package
      }
    };

    return monthPrices;
  }

  const extractDetailsFromSpecs = () => {
    return (
      specs?.flatMap((spec) => {
        const description = spec.contents[0]?.title;
        if (description !== null && description !== undefined && description !== '') {
          return {
            iconUrl: spec.contents[0]?.icon?.url || '/images/icons/icon-volant.svg',
            title: spec.title,
            description: description
          };
        }
        return [];
      }) || []
    );
  };

  const vehicleDetails = extractDetailsFromSpecs();

  return (
    <div
      className={`${css['molecule__tab-car-container']} ${className}`}
      data-active={activeSection}
      data-variant={variant}
      {...other}>
      <div className={css['card-car-container']}>
        {activeSection === 'default' && (
          <div className={css['card-car']}>
            {carlabel && <span className={css['car-label']}>{carlabel}</span>}
            <div title={carlabel ? `Modelo ${carlabel}` : undefined}>
              <ThumbHover image={image} className={css['car-image']} />
            </div>
            <div className={`${css['car-container-price-detail']}`}>
              <div className={css['car-content-price']}>
                {renderPriceComponents().map((component, index) => (
                  <React.Fragment key={index}>{component}</React.Fragment>
                ))}
              </div>

              <div className={`${css['car-content-details']}`}>
                {vehicleDetails.length > 0 && (
                  <AnchorLink
                   id={service_id+"_car-detail_"+label_id}
                    size="small"
                    color="primary"
                    hasArrow={false}
                    onClick={showCarDetails}
                    linkLabel="Detalles del vehículo"
                  />
                )}
                {prices && shouldShowPriceSection(prices) && (
                  <AnchorLink
                    id={service_id+"_car-price_"+label_id}
                    size="small"
                    color="primary"
                    hasArrow={false}
                    onClick={showCarPrices}
                    linkLabel="Todos los precios"
                  />
                )}
              </div>
            </div>
          </div>
        )}

        {activeSection === 'detail' && (
          <div className={css['card-car-detail']}>
            <div className={css['card-car-header']}>
              <div className={css['card-car-header-content']}>
                <span className={css['header-title']}>Detalles del vehículo</span>
                <span className={css['header-name']}>{carlabel}</span>
              </div>
              <IcnClose className={css['card-car-button-close']} onClick={closeSection} />
            </div>
            <div className={css['card-car-detail-content']}>
              {vehicleDetails.map(
                (spec, index) =>
                  spec.description && (
                    <div className={css['card-car-detail-content-item']} key={index}>
                      <img
                        className={css['card-car-detail-content-item-image']}
                        src={spec?.iconUrl}
                        alt={spec?.title ? `Icon ${spec?.title}` : undefined}
                        title={spec?.title ? `Icon ${spec?.title}` : undefined}
                      />
                      <div className={css['card-car-detail-content-item-description']}>
                        <span className={css['card-car-detail-content-item-description-text']}>
                          {spec?.description}
                        </span>
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
        )}
        {activeSection === 'price' && prices && shouldShowPriceSection(prices) && (
          <div className={css['card-car-price']}>
            <div className={css['card-car-header']}>
              <div className={css['card-car-header-content']}>
                <span className={css['header-title']}>Todos los precios</span>
                <span className={css['header-name']}>{carlabel}</span>
              </div>
              <IcnClose className={css['card-car-button-close']} onClick={closeSection} />
            </div>
            <div className={css['card-car-price-content']}>
              {formattedPrices?.map(
                (price, index) =>
                  price?.value && (
                    <div key={index} className={css['card-car-price-content-item']}>
                      <span>{price.interval}</span>
                      <span>
                        {' '}
                        <span
                          data-testid={`car-price-${price.defaultInterval}`}
                          className={css['card-car-price-content-item-price']}>
                          {formatToPrice(price.value)}
                        </span>
                      </span>
                    </div>
                  )
              )}
            </div>
            <div className={css['card-car-price-info']}>
              {franchise && (
                <span>
                  Franquicia:{' '}
                  <span data-testid="car-price-franchise" className={css['card-car-price-info-price']}>
                    {formatToPrice(franchise)}
                  </span>
                </span>
              )}
              {airportCharge && (
                <span>
                  Cargo Estación Aeropuerto:{' '}
                  <span data-testid="car-price-airportCharge" className={css['card-car-price-info-price']}>
                    {formatToPrice(airportCharge)}
                  </span>
                </span>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TabCar;
