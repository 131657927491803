import React, { useEffect, useState } from 'react';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Atom FormTextArea
 *
 * <!-- TODO: add a description here! -->
 */
function FormTextArea(props) {

  const [textSize, setTextSize] = useState(0);
  const [textMaxSize, setTextMaxSize] = useState(0);

  const {
    className = '',
    id,
    disabled,
    label,
    rows,
    dataRegister,
    dataErrors,
    displayLabel = true,
    displayError = true,
    displaySize = false,
    ...other
  } = props;

  useEffect(() => {
    const element = document.getElementById(id);
    if (element) {
      const textareaElement = element as HTMLTextAreaElement;
      setTextMaxSize(textareaElement.maxLength);
    }
  }, [id]);

  return (
    <div className={`${css['atom__form-text-area-container']} ${css['ff-container']} ${className}`}>
      <label
        htmlFor={id}
        className={`${css['ff-label']} ${disabled ? css['ff-disabled'] : ''} ${displayLabel ? '' : css['absent']}`}
      >
        {label}
      </label>
      <textarea
        id={id}
        data-testid={`form-text-area-${id}`}

        className={`${displayError ? css['ff-box'] : css['ff-box-clean']} ${css['field-textarea']} ${
          dataErrors?.type ? css['ff-box-error'] : ''
        } ${displaySize ? css['big-area'] : {}}`}
        {...dataRegister}
        {...other}
        rows={rows ? rows : '3'}
        disabled={disabled ? true : undefined}
        onChange={(text) => {
          setTextSize(text.target.value.length);
        }}
      ></textarea>

      {displaySize && (
        <span className={css['text-size']}>
          {textSize} / {textMaxSize} {"Caracteres"}
        </span>
      )}

      {displayError && dataErrors?.type && (
        <span className={ css['ff-error-message']}> {dataErrors.message} </span>
      )}
    </div>
  );
}

export default FormTextArea;
