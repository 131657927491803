/* eslint-disable no-unused-vars */
import { captchaToken } from 'utils/captcha/token';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AnchorLink from 'components/__atoms/anchor-link';
import Button from 'components/__atoms/button';
import FormCheckbox from 'components/__atoms/form-checkbox';
import FormInput from 'components/__atoms/form-input';
import FormSelect from 'components/__atoms/form-select';
import FormTextarea from 'components/__atoms/form-text-area';
import getConfig from 'next/config';
import i18n from 'utils/i18n';
import React, { useCallback, useEffect, useState } from 'react';
import Script from 'next/script';
import Section from 'components/__atoms/section';
import SectionHeader from 'components/__molecules/section-header';
import ResponseSubmitForm from 'components/__molecules/response-submit-form';
import Wrapper from 'components/__atoms/wrapper';
import { updateURLParameter, isNumberMask, removeSpecialCharactersAndNumbers } from 'utils/forms';

// loading the sass style fot the component
import css from './styles.module.scss';
import { empty } from 'utils/functions';
import { phoneNoDddTasaMask } from '@/utils/validations';

const { publicRuntimeConfig } = getConfig();

function ContactForm (props) {
  const t = i18n.useTranslations('components.contact-form');

  const {
    className = '',
    locale,
    spacingTop,
    spacingBottom,
    dealers = [],
    rentalServices = [],
    vehicleModels = [],
    states =[],
    productId,
    title,
    description,
    formType,
    titleSuccess,
    contentSuccess,
    titleError,
    contentError,
    ...other
  } = props;

  const [isFormIncomplete, setIsFormIncomplete] = useState(true);
  const [isStatusComponent, setIsStatusComponent] = useState('default');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [listDealers, setListDealers] = useState([]);
  const [citiesFromStates, updateCitiesFromStates] = useState([]);
  const showResponseForm = isStatusComponent === 'success' || isStatusComponent === 'error';
  let validationSchema;

    if (formType === 'KINTO_GENERAL_FORM') {
    validationSchema= yup
    .object()
    .shape({
      servicio: yup
        .string()
        .required(t('message_error_user_service')),
      firstName: yup
        .string()
        .trim()
        .required(t('message_error_required_firstName'))
        .min(1, t('message_error_min_char_firstName'))
        .max(30, t('message_error_max_char_firstName')),
      lastName: yup
        .string()
        .trim()
        .required(t('message_error_required_lastName'))
        .min(1, t('message_error_min_char_lastName'))
        .max(35, t('message_error_max_char_lastName')),
      email: yup
        .string()
        .trim()
        .required(t('message_error_required_email'))
        .email(t('message_error_invalid_email'))
        .max(80, t('message_error_max_char_email')),
      telephone : yup
        .string()
        .trim()
        .test('no-ddd', t('message_error_invalid_phone'), value => {
          if (!value) {
            return true;
          }
          return phoneNoDddTasaMask(value);
        })
        .test('min-8-digits', t('message_error_min_char_phone'), (value) => {
          if (!value) {
            return true;
          } if (value.length < 8) {
          return false;
        } else {
          return true;
    }})
        .max(12, t('message_error_max_char_phone')),
        state: yup
        .string(),
      city: yup
        .string(),
      kintoStation: yup
         .string(),
      comments: yup
        .string()
        .trim()
        .max(255, t('message_error_max_char_comentary')),
      agreement: yup
        .boolean()
        .required(t('message_error_required_agreement'))
        .oneOf([true], t('message_error_required_agreement'))
    });
  } if (formType === 'KINTO_SHARE_FORM') {
    validationSchema= yup
    .object()
    .shape({
      firstName: yup
        .string()
        .trim()
        .required(t('message_error_required_firstName'))
        .min(1, t('message_error_min_char_firstName'))
        .max(30, t('message_error_max_char_firstName')),
      lastName: yup
        .string()
        .trim()
        .required(t('message_error_required_lastName'))
        .min(1, t('message_error_min_char_lastName'))
        .max(35, t('message_error_max_char_lastName')),
      email: yup
        .string()
        .trim()
        .required(t('message_error_required_email'))
        .email(t('message_error_invalid_email'))
        .max(80, t('message_error_max_char_email')),
      telephone : yup
        .string()
        .trim()
        .test('no-ddd', t('message_error_invalid_phone'), value => {
          if (!value) {
            return true;
          }
          return phoneNoDddTasaMask(value);
        })
        .test('min-8-digits', t('message_error_min_char_phone'), (value) => {
          if (!value) {
            return true;
          } if (value.length < 8) {
          return false;
        } else {
          return true;
    }})
        .max(12, t('message_error_max_char_phone')),
        state: yup
        .string(),
      city: yup
        .string(),
      kintoStation: yup
         .string(),
      comments: yup
        .string()
        .trim()
        .max(255, t('message_error_max_char_comentary')),
      agreement: yup
        .boolean()
        .required(t('message_error_required_agreement'))
        .oneOf([true], t('message_error_required_agreement'))
    });
  } if (formType === 'KINTO_ONE_PERSONAL_FORM') {
    validationSchema= yup
    .object()
    .shape({
      firstName: yup
        .string()
        .trim()
        .required(t('message_error_required_firstName'))
        .min(1, t('message_error_min_char_firstName'))
        .max(30, t('message_error_max_char_firstName')),
      lastName: yup
        .string()
        .trim()
        .required(t('message_error_required_lastName'))
        .min(1, t('message_error_min_char_lastName'))
        .max(35, t('message_error_max_char_lastName')),
      email: yup
        .string()
        .trim()
        .required(t('message_error_required_email'))
        .email(t('message_error_invalid_email'))
        .max(80, t('message_error_max_char_email')),
      telephone : yup
        .string()
        .trim()
        .test('no-ddd', t('message_error_invalid_phone'), value => {
          if (!value) {
            return true;
          }
          return phoneNoDddTasaMask(value);
        })
        .test('min-8-digits', t('message_error_min_char_phone'), (value) => {
          if (!value) {
            return true;
          } if (value.length < 8) {
          return false;
        } else {
          return true;
    }})
        .max(12, t('message_error_max_char_phone')),
        state: yup
        .string(),
      city: yup
        .string(),
      kintoStation: yup
         .string(),
      model: yup
         .string(),
      comments: yup
        .string()
        .trim()
        .max(255, t('message_error_max_char_comentary')),
      agreement: yup
        .boolean()
        .required(t('message_error_required_agreement'))
        .oneOf([true], t('message_error_required_agreement'))
    });
  }

    const {
      register,
      reset,
      handleSubmit,
      setValue,
      watch,
      formState: { errors }
    } = useForm({
      resolver: yupResolver(validationSchema),
      mode: 'onChange',
      reValidateMode: 'onChange'
    });

    const watchedFieldsGeneralContactForm = watch([
      'servicio',
      'firstName',
      'lastName',
      'email',
      'agreement'
    ]);

    const watchedFieldsSharePersonalForm = watch([
      'firstName',
      'lastName',
      'email',
      'agreement'
    ]);

  const watchedState = watch('state');
  const watchedCity = watch('city');
  const watchedAgreement = watch('agreement');
  const watchedServicio= watch('servicio');
  const watchedKintoStation = watch('kintoStation');
  const watchedModel = watch('model');

    useEffect(() => {
      validateForm();
    }, [watchedFieldsGeneralContactForm, watchedFieldsSharePersonalForm]);

    useEffect(() => {
      if (watchedState && watchedState !== 'placeholder') {
        updateURLParameter('state', watchedState);
        loadCitiesList();
      }
    }, [watchedState]);

    useEffect(() => {
      loadDealers(watchedCity);
    }, [watchedCity]);

    function validateForm() {
      let isFormEmpty;
      if (formType === 'KINTO_GENERAL_FORM'){
        isFormEmpty = watchedFieldsGeneralContactForm.some((value) => empty(value) || value === 'placeholder');
        (isFormEmpty || (watchedAgreement === false)) ? setIsFormIncomplete(true) : setIsFormIncomplete(false);
      } else {
        isFormEmpty = watchedFieldsSharePersonalForm.some((value) => empty(value) || value === 'placeholder');
        (isFormEmpty || (watchedAgreement === false)) ? setIsFormIncomplete(true) : setIsFormIncomplete(false);
      }
    }

    const resetForm = useCallback(() => {
      let result;
      if (formType === 'KINTO_GENERAL_FORM') {
        result = { servicio: 'placeholder', firstName: '', lastName: '', email: '', telephone: '',
        state: 'placeholder', city: 'placeholder', kintoStation: 'placeholder', comments: '',  agreement: false };
      } if (formType === 'KINTO_SHARE_FORM') {
        result = { firstName: '', lastName: '', email: '', telephone: '', state: 'placeholder',
        city: 'placeholder', kintoStation: 'placeholder', comments: '',  agreement: false };
      }if (formType === 'KINTO_ONE_PERSONAL_FORM') {
        result = { firstName: '', lastName: '', email: '', telephone: '', state: 'placeholder',
        city: 'placeholder', kintoStation: 'placeholder', model:'placeholder', comments: '',  agreement: false };
      }
      reset(result);
    }, [reset]);

    function loadCitiesList() {
      const dealersFromState = dealers?.filter(
        (dealer) => dealer?.address?.state.toUpperCase() === watchedState?.toUpperCase()
      );

      const uniqueCitiesList = Array.from(
        new Set(dealersFromState.map((dealer) => dealer.address.city.toUpperCase()))
      ).sort();

      updateCitiesFromStates(uniqueCitiesList);
    }

    function loadDealers(selectedCity) {
      const filtered = dealers?.filter((dealer) => {
        return dealer?.address?.city.toUpperCase() === selectedCity?.toUpperCase();
      });
      setListDealers(filtered);
    }

    function onChangeState(event) {
      setValue('city', 'placeholder');
      updateURLParameter('city', null);
      setValue('kintoStation', 'placeholder');
      setValue('state', event.target.value);
    }

    function onChangeCity(e) {
      setValue('kintoStation', 'placeholder');
      updateURLParameter('city', e.currentTarget.value);
      setValue('city', e.currentTarget.value);
    }

    function onChangeDealers(e) {
      setValue('kintoStation', e.currentTarget.value);
    }

    function getDealerSelect(selectedDealerName) {
      const selectedDealer = listDealers?.find(
        (dealer) => dealer?.name.toUpperCase() === selectedDealerName?.toUpperCase()
      );

      return selectedDealer;
    }

    const onSubmit = async (data) => {
      setIsSubmitting(true);
      data.locale = locale;
      data.type =  formType.toUpperCase();

      const captchaPayload = await captchaToken('LEAD', publicRuntimeConfig?.G_RECAPTCHA_TOKEN || '');

      if (!captchaPayload) {
         console.debug('Falhou verificação do recaptcha');
         setIsSubmitting(false);

        return false;
      }
       data.captchaPayload = captchaPayload;

      if (data.kintoStation && data.kintoStation !== 'placeholder') {
        data.dealerId = getDealerSelect(data.kintoStation).id;
      }

      delete data.agreement;

      data.productId = productId;

      if(formType === 'KINTO_SHARE_FORM') {
        data.servicio = 'KINTO Share';
      }

      if(formType === 'KINTO_ONE_PERSONAL_FORM') {
        data.servicio = 'KINTO One Personal';
      }

      for (const field in data) {
        if (data[field] === 'placeholder' || data[field] === '') {
          delete data[field];
        }
      }

      const opts = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      };

      const url = '/api/lead';

      fetch(url, opts)
        .then((res) => {
          if (res.status === 200) {
            setIsSubmitting(false);
            resetForm();
            updateURLParameter('state', null);
            updateURLParameter('city', null);
            setIsStatusComponent('success');

            return;
          }
          setIsSubmitting(false);
          setIsStatusComponent('error');
        })
        .catch((err) => {
          setIsSubmitting(false);
          console.log(err);
        });
};

    function onError(error) {
      console.log('Error: ', error);
    }

    function onBackForm() {
      setIsStatusComponent('default');
    }

    const getTitleAndContent = (isStatusComponent) => {

      switch (isStatusComponent) {
        case 'success':
          return {
            title: titleSuccess ? titleSuccess : t('success_title'),
            content: contentSuccess ? contentSuccess : t('success_content'),
            contentBackForm: t('content_back_form_success')
          };
        case 'error':
          return {
            title: titleError ? titleError : t('error_title'),
            content: contentError ? contentError : t('error_content'),
            contentBackForm: t('content_back_form_error')
          };
        case 'default':
          return {
            title: title ? title : t('title_form'),
            content: description ? description : t('description_form'),
            contentBackForm: t('content_back_form_success')
          };
        default:
          return {
            title: '',
            content: '',
            contentBackForm: ''
          };
      }
    };

  return <div
    className={`${css["contact-form-container"]} ${className}`}
    {...other}
  >
      <Wrapper spacingTop={spacingTop} spacingBottom={spacingBottom}>
        <Section className={css['success-container']}>
        <Script
          src={`https://www.google.com/recaptcha/enterprise.js?render=${publicRuntimeConfig.G_RECAPTCHA_TOKEN}`}
        ></Script>

{ showResponseForm &&
          <ResponseSubmitForm
            className={css['result_submit']}
            onBackForm={onBackForm}
            {...getTitleAndContent(isStatusComponent)}
            contentBackHome={t('content_back_home')}
          />
        }

        {isStatusComponent === 'default' && (
          <fieldset className={css['fieldset']}>
              <SectionHeader
                title={title ? title : t('title_form')}
                description={{
                  value: description ? description : t('description_form'),
                  textColor: 'gray-4'
                }}
              />


              <form onSubmit={handleSubmit(onSubmit, onError)} data-grid data-grid-list='3'>
              <fieldset className={css['fieldset-form']}>
              {formType === 'KINTO_GENERAL_FORM' &&
                             <fieldset className={css['fieldset-input']}>
                             <FormSelect
                             className={`${css['form-field-service']} ${watchedServicio === ('placeholder' || undefined) && css['placeholder-selected']}`}
                             id="servicio"
                             placeholder={t('placeholder_user_service_field')}
                             label={t('label_user_service_field')}
                             dataRegister={register('servicio')}
                             dataErrors={errors['servicio']}
                           >
                            {rentalServices.length > 0 &&
                      rentalServices?.map((service, index) => {
                        return (
                          <option key={index} value={service.name}>
                            {service.name}
                          </option>
                        );
                      })}
                           </FormSelect></fieldset>}
            <fieldset className={css['fieldset-input']}>
              <div data-grid-full-width>
              <p className={css['subtitle']}>{t('subtitle')}</p>
              </div>
              <FormInput
                className={css['form-field']}
                id="firstName"
                label={t('label_user_firstName_field')}
                placeholder={t('placeholder_user_firstName_field')}
                dataRegister={register('firstName')}
                masking={(event) => {
                  const value = event.target.value;
                  event.target.value = removeSpecialCharactersAndNumbers(value);
                }}
                maxLength='30'
                dataErrors={errors['firstName']}
                type="text"
              />
              <FormInput
                className={css['form-field']}
                id="lastName"
                label={t('label_user_lastName_field')}
                placeholder={t('placeholder_user_lastName_field')}
                dataRegister={register('lastName')}
                masking={(event) => {
                  const value = event.target.value;
                  event.target.value = removeSpecialCharactersAndNumbers(value);
                }}
                dataErrors={errors['lastName']}
                maxLength='35'
                type="text"
              />
                     <FormInput
                  className={css['form-field']}
                  id="email"
                  label={t('label_user_email_field')}
                  dataRegister={register('email')}
                  maxLength='80'
                  dataErrors={errors['email']}
                  type="text"
                  placeholder={t('placeholder_user_email_field')}
                />
                <FormInput
                className={css['form-field']}
                id="telephone"
                label={t('label_user_phone_field')}
                placeholder={t('placeholder_user_phone_field')}
                dataRegister={register('telephone')}
                maxLength='12'
                dataErrors={errors['telephone']}
                masking={(event) => {
                  const value = event.target.value;
                  event.target.value = isNumberMask(value);
                }}
              />
              </fieldset>
              <fieldset className={css['fieldset-input']}>
              <div data-grid-full-width>
               <p className={css['subtitle']}>
                {formType === 'KINTO_ONE_PERSONAL_FORM' ? t('representante_kinto') : t('busca_kinto_station')}</p>
              </div>
                  <FormSelect
                    className={`${css['form-field-service']} ${watchedState === ('placeholder' || undefined) && css['placeholder-selected']}`}
                    id="state"
                    label={t('label_user_state_field')}
                    placeholder={t('placeholder_user_state_field')}
                    onChange={onChangeState}
                    dataRegister={register('state')}
                    dataErrors={errors['state']}
                  >
                    {states.length > 0 &&
                      states?.map((state, index) => {
                        return (
                          <option key={index} value={state}>
                            {state}
                          </option>
                        );
                      })}
                  </FormSelect>
                  <FormSelect
                    className={`${css['form-field-service']} ${watchedCity === ('placeholder' || undefined) && css['placeholder-selected']}`}
                    id="city"
                    label={t('label_user_city_field')}
                    placeholder={t('placeholder_user_city_field')}
                    onChange={onChangeCity}
                    dataRegister={register('city')}
                    dataErrors={errors['city']}
                  >
                    {watchedState &&
                      citiesFromStates?.map((city, index) => {
                        return (
                          <option key={index} value={city}>
                            {city}
                          </option>
                        );
                      })}
                  </FormSelect>
                  <FormSelect
                    className={`${css['form-field-service']} ${watchedKintoStation === ('placeholder' || undefined) && css['placeholder-selected']}`}
                    id="kintoStation"
                    label={t('label_user_kinto_station_field')}
                    placeholder={t('placeholder_user_kinto_station_field')}
                    onChange={onChangeDealers}
                    dataRegister={register('kintoStation')}
                    dataErrors={errors['kintoStation']}
                  >
                    {watchedCity &&
                      listDealers?.map((dealer, index) => {
                        return (
                          <option key={index} value={dealer.name}>
                            {dealer.name}
                          </option>
                        );
                      })}
                  </FormSelect>

                  {formType === 'KINTO_ONE_PERSONAL_FORM' &&
                             <FormSelect
                             className={`${css['form-field-service']} ${watchedModel === ('placeholder' || undefined) && css['placeholder-selected']}`}
                             id="model"
                             placeholder={t('placeholder_user_model_field')}
                             label={t('label_user_model_field')}
                             dataRegister={register('model')}
                             dataErrors={errors['model']}
                           >
                    {vehicleModels.length > 0 &&
                      vehicleModels?.map((model, index) => {
                        return (
                          <option key={index} value={model.name}>
                            {model.name}
                          </option>
                        );
                      })}
                           </FormSelect>}

                <div data-grid-full-width>
                  <FormTextarea
                    className={css['form-field']}
                    id="comments"
                    type="text"
                    placeholder={t('placeholder_user_comentary_field')}
                    label={t('label_user_comentary_field')}
                    maxLength='255'
                    dataRegister={register('comments')}
                    dataErrors={errors['comments']}
                  />
                </div>


                <div data-grid-full-width>
                  <FormCheckbox
                    id="agreement"
                    dataRegister={register('agreement')}
                    dataErrors={errors['agreement']}
                    className={css['agreement']}
                  >
                    {t('label_agreement')}&nbsp;
                    <AnchorLink
                      link='https://media.toyota.com.ar/df0896a8-b7b0-4abd-bf52-d2395a8d93b4.pdf'
                      hasArrow={false}
                      linkLabel={t('link_label_agreement')}
                    />
                  </FormCheckbox>
                </div>

                <div data-grid-full-width className={css['form-submit-container']}>
                  <Button type="submit" className={css['submit_button']} disabled={Object.keys(errors).length > 0 || isFormIncomplete || isSubmitting}>
                    {t('button_submit_form')}
                  </Button>
                </div>
                </fieldset>
                </fieldset>
              </form>
            </fieldset>
          )}
        </Section>
      </Wrapper>
  </div>;
}

export default ContactForm;
