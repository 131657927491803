import React, { useState, useEffect, useCallback } from 'react';

import AnchorLink from 'components/__atoms/anchor-link';
import Button from 'components/__atoms/button';
import FormCheckbox from 'components/__atoms/form-checkbox';
import FormInput from 'components/__atoms/form-input';
import FormSelect from 'components/__atoms/form-select';
import FormTextArea from 'components/__atoms/form-text-area';
import Section from 'components/__atoms/section';
import Wrapper from 'components/__atoms/wrapper';
import ResponseSubmitForm from 'components/__molecules/response-submit-form';
import SectionHeader from 'components/__molecules/section-header';

import getConfig from 'next/config';
import Script from 'next/script';
import i18n from 'utils/i18n';

import { captchaToken } from 'utils/captcha/token';
import { sendRequest, isNumberMask, removeSpecialCharactersAndNumbers } from 'utils/forms';
import { empty } from 'utils/functions';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// loading the sass style fot the component
import css from './styles.module.scss';
import { phoneNoDddTasaMask } from '@/utils/validations';

const { publicRuntimeConfig } = getConfig();

function CorporateContactForm(props) {
  const t = i18n.useTranslations('components.corporate-contact-form');

  const {
    className = '',
    dealers = [],
    states =[],
    titleSuccess,
    contentSuccess,
    titleError,
    contentError,
    title,
    description,
    spacingTop,
    spacingBottom,
    productId,
    locale,
    ...other
  } = props;

  const validationSchemaConfig = {
    socialName: yup
      .string()
      .trim()
      .required(t('message_error_required_socialName'))
      .max(255, t('message_error_max_char_socialName')),
    cuit: yup
      .string()
      .trim()
      .required(t('message_error_required_cuit'))
      .max(11, t('message_error_max_char_cuit')),
    companyName: yup
      .string()
      .trim()
      .max(255, t('message_error_max_char_companyName')),
    firstName: yup
      .string()
      .trim()
      .required(t('message_error_required_firstName'))
      .min(1, t('message_error_min_char_firstName'))
      .max(40, t('message_error_max_char_firstName')),
    lastName: yup
      .string()
      .trim()
      .required(t('message_error_required_lastName'))
      .min(1, t('message_error_min_char_lastName'))
      .max(80, t('message_error_max_char_lastName')),
    email: yup
      .string()
      .required(t('message_error_required_email'))
      .max(80, t('message_error_max_char_email'))
      .email(t('message_error_invalid_email')),
    telephone: yup
      .string()
      .test('no-ddd', t('message_error_invalid_telephone'), value => {
        if (!value) {
          return true;
        }
        return phoneNoDddTasaMask(value);
      })
      .test('min-8-digits', t('message_error_min_char_telephone'), (value) => {
        if (!value) {
          return true;
        } if (value.length < 8) {
        return false;
      } else {
        return true;
  }})
      .max(12, t('message_error_max_char_telephone')),
    state: yup
      .string().required(t('message_error_required_state')),
    city: yup
      .string().required(t('message_error_required_city')),
    dealerId: yup
      .string().required(t('message_error_required_dealerId')),
    fleetSize: yup
      .string()
      .max(255, t('message_error_max_char_fleetSize')),
    contractDuration: yup
      .string(),
    km: yup
      .string(),
    comments: yup
      .string()
      .max(255, t('message_error_max_char_comments')),
    agreement: yup
      .boolean()
      .oneOf([true], t('message_error_required_agreement')),
  };

  const validationSchema = yup.object().shape(validationSchemaConfig, [['telephone', 'telephone']]);

  const {
    register,
    reset,
    watch,
    setValue,
    handleSubmit,
    trigger,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const [isStatusComponent, setIsStatusComponent] = useState('default');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormIncomplete, setIsFormIsIncomplete] = useState(true);
  const [listCities, setListCities] = useState([]);
  const [listDealers, setListDealers] = useState([]);

  const showResponseForm = isStatusComponent === 'success' || isStatusComponent === 'error';

  const watchedFields = watch([
    'socialName',
    'cuit',
    'firstName',
    'lastName',
    'email',
    'agreement',
    'state',
    'city',
    'dealerId'
  ]);

  const watchedState = watch('state');
  const watchedCity = watch('city');
  const watchedAgreement = watch('agreement');
  const watchedDealerId = watch('dealerId');
  const watchedContractDuration = watch('contractDuration');
  const watchedKm = watch('km');

  function validateForm() {
    const isFormEmpty = watchedFields.some((value) => empty(value)
      || value === 'placeholder'
      ||  (typeof value === 'string'
      && (typeof value === 'string'
      && value?.trim() === '')));
      (isFormEmpty || (watchedAgreement === false)) ? setIsFormIsIncomplete(true) : setIsFormIsIncomplete(false);
  }

  const resetForm = useCallback(() => {
    const result = { socialName: '', cuit: '', companyName:'', firstName: '', lastName: '', email: '', telephone: '', state: 'placeholder',
      city: 'placeholder', dealerId: 'placeholder', fleetSize:'', contractDuration: 'placeholder', km: 'placeholder',
      comments: '',  agreement: false };
    reset(result);
  }, [reset]);

  function onBackForm() {
    setIsStatusComponent('default');
  }

  function loadCities(selectedState) {
    const filtered = dealers?.filter((state) => {
      return state?.address?.state.toUpperCase() === selectedState?.toUpperCase();
    });
    const resultCities = [...new Set(filtered.map((city) => city.address.city.toUpperCase()))].sort();

    setListCities(resultCities);
  }

  function loadDealers(selectedCity) {
    const filtered = dealers?.filter((dealer) => {
      return dealer?.address?.city.toUpperCase() === selectedCity?.toUpperCase();
    });
    setListDealers(filtered);
  }

  function onChangeState(e) {
    setValue('city', 'placeholder');
    setValue('dealerId', 'placeholder');
    setValue('state', e.currentTarget.value);
  }

  function onChangeCity(e) {
    setValue('dealerId', 'placeholder');
    setValue('city', e.currentTarget.value);
  }

  function onChangeDealers(e) {
    setValue('dealerId', e.currentTarget.value);
    trigger('dealerId');
  }

  function findDealerId(dealerName) {
    const dealer = listDealers.find(dealer => dealer?.name.toUpperCase() === dealerName?.toUpperCase());
    return dealer.id;
  }

  const handleResponse = (success) => {
    if (success) {
      setIsStatusComponent('success');

      resetForm();
      return;
    }
    setIsStatusComponent('error');
    return;
  };

  const onSubmit = async (data, event) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {

       const captchaPayload = await captchaToken('CONTACT', publicRuntimeConfig?.G_RECAPTCHA_TOKEN || '');
       if (!captchaPayload) {
         console.debug('Recaptcha verification failed.');
        handleResponse(false);
        return;
       }

      data.captchaPayload = captchaPayload;
      data.locale = locale;
      data.type = 'KINTO_ONE_FLEET_FORM';
      data.productId = productId;
      data.dealerId = findDealerId(data.dealerId);
      delete data?.agreement;

      for (const field in data) {
        if (data[field] === 'placeholder' || data[field] === '') {
          delete data[field];
        }
      }

       const response = await sendRequest('POST', 'api/contact', data);

      handleResponse(response.ok && response.status === 200);
    } catch (error) {
      handleResponse(false);
      console.error('Error while submitting the form:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onError = (errors, e) => console.log(errors, e);

  const getTitleAndContent = (isStatusComponent) => {

    switch (isStatusComponent) {
      case 'success':
        return {
          title: titleSuccess ? titleSuccess : t('success_title'),
          content: contentSuccess ? contentSuccess : t('success_content'),
          contentBackForm: t('content_back_form_success')
        };
      case 'error':
        return {
          title: titleError ? titleError : t('error_title'),
          content: contentError ? contentError : t('error_content'),
          contentBackForm: t('content_back_form_error')
        };
      case 'default':
        return {
          title: title ? title : t('title_form'),
          content: description ? description : t('description_form'),
          contentBackForm: t('content_back_form_success')
        };
      default:
        return {
          title: '',
          content: '',
          contentBackForm: ''
        };
    }
  };

  useEffect(() => {
    loadCities(watchedState);
  }, [watchedState]);

  useEffect(() => {
    loadDealers(watchedCity);
  }, [watchedCity]);

  useEffect(() => {
    validateForm();
  }, [watchedFields]);

  return (
    <Wrapper  spacingTop={spacingTop} spacingBottom={spacingBottom} className={`${css["corporate-contact-form-container"]} ${className}`}
    {...other}>
      <Section>
        <Script
          src={`https://www.google.com/recaptcha/enterprise.js?render=${publicRuntimeConfig.G_RECAPTCHA_TOKEN}`}
        ></Script>

        { showResponseForm &&
          <ResponseSubmitForm
            className={css['result_submit']}
            onBackForm={onBackForm}
            {...getTitleAndContent(isStatusComponent)}
            contentBackHome={t('content_back_home')}
          />
        }

        {isStatusComponent === 'default' && (
          <fieldset className={css['fieldset']}>
            <SectionHeader
              title={getTitleAndContent(isStatusComponent).title}
              description={{
                value: getTitleAndContent(isStatusComponent).content,
                textColor: 'gray-4'
              }}
            />
            <form onSubmit={handleSubmit(onSubmit, onError)} data-grid data-grid-list='3'>
            <fieldset className={css['fieldset-form']}>
            <fieldset className={css['fieldset-input']}>
            <div data-grid-full-width>
              <p className={css['subtitle']}>{t('subtitle')}</p>
              </div>
              <FormInput
                className={css['form-field']}
                id="socialName"
                label={t('label_user_socialName_field')}
                placeholder={t('placeholder_user_socialName_field')}
                dataRegister={register('socialName')}
                maxLength='255'
                dataErrors={errors['socialName']}
                type="text"
              />
                            <FormInput
                className={css['form-field']}
                id="cuit"
                label={t('label_user_cuit_field')}
                placeholder={t('placeholder_user_cuit_field')}
                dataRegister={register('cuit')}
                masking={(event) => {
                  const value = event.target.value;
                  event.target.value = isNumberMask(value);
                }}
                maxLength='11'
                dataErrors={errors['cuit']}
                type="text"
              />
                            <FormInput
                className={css['form-field']}
                id="companyName"
                label={t('label_user_companyName_field')}
                placeholder={t('placeholder_user_companyName_field')}
                dataRegister={register('companyName')}
                maxLength='255'
                dataErrors={errors['companyName']}
                type="text"
              />
              <FormInput
                className={css['form-field']}
                id="firstName"
                label={t('label_user_firstName_field')}
                placeholder={t('placeholder_user_firstName_field')}
                dataRegister={register('firstName')}
                maxLength='40'
                masking={(event) => {
                  const value = event.target.value;
                  event.target.value = removeSpecialCharactersAndNumbers(value);
                }}
                dataErrors={errors['firstName']}
                type="text"
              />
              <FormInput
                className={css['form-field']}
                id="lastName"
                label={t('label_user_lastName_field')}
                placeholder={t('placeholder_user_lastName_field')}
                dataRegister={register('lastName')}
                masking={(event) => {
                  const value = event.target.value;
                  event.target.value = removeSpecialCharactersAndNumbers(value);
                }}
                dataErrors={errors['lastName']}
                maxLength='80'
                type="text"
              />
                     <FormInput
                  className={css['form-field']}
                  id="email"
                  label={t('label_user_email_field')}
                  dataRegister={register('email')}
                  maxLength='80'
                  dataErrors={errors['email']}
                  type="text"
                  placeholder={t('placeholder_user_email_field')}
                />
                <FormInput
                className={css['form-field']}
                id="telephone"
                label={t('label_user_telephone_field')}
                placeholder={t('placeholder_user_telephone_field')}
                dataRegister={register('telephone')}
                maxLength='12'
                dataErrors={errors['telephone']}
                masking={(event) => {
                  const value = event.target.value;
                  event.target.value = isNumberMask(value);
                }}
              />
              </fieldset>
              <fieldset className={css['fieldset-input']}>
              <div data-grid-full-width>
               <p className={css['subtitle']}>
                {t('representante_kinto')}
               </p>
              </div>
                  <FormSelect
                    className={`${css['form-field']} ${watchedState === ('placeholder' || undefined) && css['placeholder-select']}`}
                    id="state"
                    label={t('label_user_state_field')}
                    placeholder={t('placeholder_user_state_field')}
                    onChange={onChangeState}
                    dataRegister={register('state')}
                    dataErrors={errors['state']}
                  >
                    {states.length > 0 &&
                      states?.map((state, index) => {
                        return (
                          <option key={index} value={state}>
                            {state}
                          </option>
                        );
                      })}
                  </FormSelect>
                  <FormSelect
                    className={`${css['form-field']} ${watchedCity === ('placeholder' || undefined) && css['placeholder-select']}`}
                    id="city"
                    label={t('label_user_city_field')}
                    placeholder={t('placeholder_user_city_field')}
                    onChange={onChangeCity}
                    dataRegister={register('city')}
                    dataErrors={errors['city']}
                  >
                    {watchedState &&
                      listCities?.map((city, index) => {
                        return (
                          <option key={index} value={city}>
                            {city}
                          </option>
                        );
                      })}
                  </FormSelect>
                  <FormSelect
                    className={`${css['form-field']} ${watchedDealerId === ('placeholder' || undefined) && css['placeholder-select']}`}
                    id="dealerId"
                    label={t('label_user_dealerId_field')}
                    placeholder={t('placeholder_user_dealerId_field')}
                    onChange={onChangeDealers}
                    dataRegister={register('dealerId')}
                    dataErrors={errors['dealerId']}
                  >
                    {watchedCity &&
                      listDealers?.map((dealer, index) => {
                        return (
                          <option key={index} value={dealer.name}>
                            {dealer.name}
                          </option>
                        );
                      })}
                  </FormSelect>
                  </fieldset>
                  <fieldset className={css['fieldset-input']}>
                  <div data-grid-full-width>
              <p className={css['subtitle']}>{t('datos_flota')}</p>
              </div>
              <FormInput
                  className={css['form-field']}
                  id="fleetSize"
                  label={t('label_user_fleetSize_field')}
                  dataRegister={register('fleetSize')}
                  maxLength='255'
                  dataErrors={errors['fleetSize']}
                  type="text"
                  placeholder={t('placeholder_user_fleetSize_field')}
                />
              <FormSelect
                    className={`${css['form-field']} ${watchedContractDuration === ('placeholder' || undefined) && css['placeholder-select']}`}
                    id="contractDuration"
                    label={t('label_user_contractDuration_field')}
                    placeholder={t('placeholder_user_contractDuration_field')}
                    dataRegister={register('contractDuration')}
                    dataErrors={errors['contractDuration']}
                  >
                  <option key={t('option1_user_contractDuration_field')} value={t('option1_user_contractDuration_field')}>
                    {t('option1_user_contractDuration_field')}
                  </option>
                  <option key={t('option2_user_contractDuration_field')} value={t('option2_user_contractDuration_field')}>
                    {t('option2_user_contractDuration_field')}
                  </option>
                  <option key={t('option3_user_contractDuration_field')} value={t('option3_user_contractDuration_field')}>
                    {t('option3_user_contractDuration_field')}
                  </option>
                  <option key={t('option4_user_contractDuration_field')} value={t('option4_user_contractDuration_field')}>
                    {t('option4_user_contractDuration_field')}
                  </option>
                  <option key={t('option5_user_contractDuration_field')} value={t('option5_user_contractDuration_field')}>
                    {t('option5_user_contractDuration_field')}
                  </option>
                  </FormSelect>
                  <FormSelect
                    className={`${css['form-field']} ${watchedKm === ('placeholder' || undefined) && css['placeholder-select']}`}
                    id="km"
                    label={t('label_user_km_field')}
                    placeholder={t('placeholder_user_km_field')}
                    dataRegister={register('km')}
                    dataErrors={errors['km']}
                  >
                  <option key={t('option1_user_km_field')} value={t('option1_user_km_field')}>
                    {t('option1_user_km_field')}
                  </option>
                  <option key={t('option2_user_km_field')} value={t('option2_user_km_field')}>
                    {t('option2_user_km_field')}
                  </option>
                  <option key={t('option3_user_km_field')} value={t('option3_user_km_field')}>
                    {t('option3_user_km_field')}
                  </option>
                  </FormSelect>

                <div data-grid-full-width>
                  <FormTextArea
                    className={css['form-field']}
                    id="comments"
                    type="text"
                    placeholder={t('placeholder_user_comments_field')}
                    label={t('label_user_comments_field')}
                    maxLength='255'
                    dataRegister={register('comments')}
                    dataErrors={errors['comments']}
                  />
                </div>


                <div data-grid-full-width>
                  <FormCheckbox
                    id="agreement"
                    dataRegister={register('agreement')}
                    dataErrors={errors['agreement']}
                    className={css['agreement']}
                  >
                    {t('label_agreement')}&nbsp;
                    <AnchorLink
                      link='https://media.toyota.com.ar/df0896a8-b7b0-4abd-bf52-d2395a8d93b4.pdf'
                      hasArrow={false}
                      linkLabel={t('link_label_agreement')}
                    />
                  </FormCheckbox>
                </div>

                <div data-grid-full-width className={css['form-submit-container']}>
                  <Button type="submit" className={css['submit_button']} disabled={Object.keys(errors).length > 0 || isFormIncomplete || isSubmitting}>
                    {t('button_submit_form')}
                  </Button>
                </div>
                </fieldset>
                </fieldset>
            </form>
          </fieldset>
        )}
      </Section>
    </Wrapper>
  );
}

export default CorporateContactForm;
