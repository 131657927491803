import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor, bgColor, spacingBottom, spacingTop } from 'utils/meta';

class FaqSection implements ContentBlock {
  label = 'FAQ';

  component = 'FaqSection';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = true;

  settings = [
    {
      name: 'invertArrow',
      label: 'Invert the button arrow',
      help: 'Invert the position of the arrow',
      type: 'boolean',
      control: 'toggle',
      defaultValue: false
    },
    {
      name: 'type',
      label: 'Layout type',
      help: 'The layout type of the list',
      type: 'String',
      control: 'select',
      options: ['default', '2']
    },
    {
      name: 'sectionWidth',
      label: 'Section Width',
      help: 'The width of the faq list',
      type: 'String',
      control: 'select',
      options: ['default', 'full-width']
    },
    bgColor,
    spacingTop,
    spacingBottom,
    anchor
  ];

  properties = [
    {
      name: 'title',
      label: 'Title',
      help: 'The title of the content',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'description',
      label: 'Content',
      help: 'The content of the item',
      type: 'String',
      control: 'editor',
      defaultValue: '',
      props: null
    }
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async adapter(contents: any[], extra: any): Promise<object> {
    return {
      sectionTitle: extra?.section?.name,
      bgColor: extra?.settings?.bgColor,
      invertArrow: extra?.settings?.invertArrow,
      type: extra?.settings?.type,
      sectionWidth: extra?.settings?.sectionWidth,
      spacingTop: extra?.settings?.spacingTop,
      spacingBottom: extra?.settings?.spacingBottom,
      faqContent: {
        items: contents.map((content) => {
          return {
            title: content?.title,
            content: content?.description
          };
        })
      }
    };
  }
}

export { FaqSection };
