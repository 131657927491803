import { PageBuilder, buildComponentBuilder } from 'utils/page';
import Header from 'components/__organisms/header';
import PageMetaContent from 'components/__atoms/page-meta-content';
import { PageProvider } from 'providers/page';
import { useRouter } from 'next/router';
import getConfig from 'next/config';
import i18n from 'utils/i18n';
import { Logger } from '@on2-dev/on2-frontend-common/index.cjs';
import Menu from 'utils/repo/menu';
import PageRepo from 'utils/repo/page';
import React from 'react';
import Repo from 'utils/repo';
import * as Kinto_Components from 'components/_index';
import * as Kinto_Templates from 'templates/_index';
import * as General_Components from '@on2-dev/tyt-general-resources/components';

const AllTemplates = {
  Kinto: Kinto_Templates
};

const AllComponents = {
  Kinto: Kinto_Components,
  General: General_Components
};

const { serverRuntimeConfig } = getConfig();

const slugsCached = {};

export default function GenericPage(props) {
  const router = useRouter();

  if (router.isFallback) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '80vh',
          background: '#000'
        }}></div>
    );
  }

  const buildComponent = buildComponentBuilder(AllComponents);
  const components = [];

  if (props?.pageBlocks) {
    props.pageBlocks.forEach((tuple, idx) => {
      components.push(
        buildComponent(tuple.props.fullComponentName, `dynamic-component-${idx}`, tuple.props, tuple?.children || [])
      );
    });
  }

  return (
    <>
      <PageMetaContent {...props} />
      <Header menuData={props?.menuData || []} fullpath={props?.fullpath} />
      <PageProvider>
        <main id="main">{components}</main>
      </PageProvider>
    </>
  );
}

/**
 * Builder for breadcrumb during full build rountine (when all pages are
 * rendered at once).
 *
 * @param {string} current Fullpath of current rendenring page
 * @returns Array of objects with slug and name
 */
function breadcrumbBuilderFromPath(current) {
  const crumbs = [];

  // eslint-disable-next-line no-constant-condition
  while (true) {
    current = current.endsWith('/') && current.length > 0 ? current.slice(0, -1) : current;

    if (current.length === 0 || !current.includes('/')) {
      break;
    }

    crumbs.push(slugsCached[current]);

    let parts = current.split('/');
    parts.pop();
    current = parts.join('/');
  }

  crumbs.push({ slug: '/', name: 'Home', id: '' });

  return crumbs.reverse();
}

/**
 * Builder for breadcrumb during SSR or ISR rendering of one page
 *
 * @param {Page} page Object with data of current page
 * @return Promise<Array>
 */
async function breadcrumbBuilderFromTree(page) {
  let crumbs = [];
  let pages = [];

  try {
    const breadcrumbResult = await Repo.get(PageRepo.findPageTree(), { id: page.id });
    if (!breadcrumbResult?.data?.pageTree?.length) {
      return [];
    }

    pages = breadcrumbResult?.data?.pageTree || [];
  } catch (err) {
    Logger.debug('[Page::breadcrumb] Failed to get page tree with query. Reason:', err);

    return [];
  }

  crumbs = pages.map((entry) => {
    return {
      slug: entry.fullpath,
      name: entry.title,
      id: entry.id
    };
  });

  crumbs.push({ slug: '/', name: 'Home', id: '' });

  return crumbs.reverse();
}

export async function getStaticPaths() {
  const productId = serverRuntimeConfig.PRODUCT_ID;

  // find all disponible pages
  const pagesResult = await Repo.get(PageRepo.findAllByLanguage(), { productId });
  let pages = [];
  if (pagesResult?.data?.pages) {
    pages = pagesResult.data.pages;
  }

  // map the paths
  let paths = [];
  if (pages && pages?.length) {
    // make sure to process parent pages before its childrens
    pages.sort((a, b) => ('' + a.fullpath).localeCompare(b.fullpath));

    paths = pages.map((page) => {
      slugsCached[page.fullpath] = {
        slug: page.fullpath,
        name: page.title,
        id: page.id
      };

      let slug = page.fullpath;
      if (slug.startsWith('/')) {
        slug = slug.substring(1);
      }

      const props = {
        params: {
          slug: slug.split('/'),
          breadcrumb: breadcrumbBuilderFromPath(page.fullpath)
        }
      };

      return props;
    });
  }

  return {
    paths,
    fallback: true // false or 'blocking'
  };
}

export async function getStaticProps({ locale, params }) {
  const productId = serverRuntimeConfig.PRODUCT_ID;

  let slug = params?.slug || '/';

  if (Array.isArray(slug)) {
    slug = slug.join('/');
  }

  if (slug.charAt(0) !== '/') {
    slug = `/${slug}`;
  }

  let page = false;
  try {
    const pageResult = await Repo.get(PageRepo.findBySlug(), { fullpath: slug, productId: productId });
    if (pageResult?.data?.pageByPathAndProduct) {
      page = pageResult.data.pageByPathAndProduct;
    }
  } catch (err) {
    page = false;
  }

  // if nothing is found or status is not published, show the 404 page
  if (!page || page.status !== 'PUBLISHED') {
    return {
      redirect: {
        destination: '/404',
        permanent: false
      }
    };
  }

  const menuData = await Menu.getMenuItemsByType({ type: 'main', productId });
  if (!params?.breadcrumb) {
    params.breadcrumb = await breadcrumbBuilderFromTree(page);
  }

  try {
    const i18nMessages = await i18n.getMessages(locale);
    const pageBuilder = new PageBuilder(page, { repo: Repo });
    pageBuilder.setTemplates(AllTemplates);

    const pageBlocks = await pageBuilder.page({ params, locale, productId, i18nMessages });
    if (!pageBlocks) {
      return {
        redirect: {
          destination: '/404',
          permanent: false
        }
      };
    }

    const titleConfig = page?.configs?.find((c) => c.name === 'title');
    return {
      revalidate: serverRuntimeConfig.REVALIDATION_INTERVAL, // ISR: just revalidate at given interval
      props: removeUndefineds({
        ...page,
        messages: i18nMessages,
        pageBlocks: pageBlocks,
        menuData,
        locale,
        title: titleConfig?.value || page?.title
      })
    };
  } catch (err) {
    Logger.error(`[Page::staticProps] Failed to generate page '${slug}' at '${locale}'. Reason: `, err);

    throw err;
  }
}

function removeUndefineds(obj) {
  // cleaning an array
  if (Array.isArray(obj)) {
    const arr = obj;
    const newArr = [];
    arr.forEach((val, key) => {
      if (typeof val === 'undefined') {
        return;
      }
      if (val === Object(val)) {
        // this is an object, not a regula value
        newArr[key] = removeUndefineds(val);
      } else {
        newArr[key] = val;
      }
    });
    return newArr;
  }

  // cleaning an object
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    const val = obj[key];
    if (typeof val === 'undefined') {
      return;
    }
    if (val === Object(val)) {
      // this is an object, not a regula value
      newObj[key] = removeUndefineds(val);
    } else {
      newObj[key] = val;
    }
  });
  return newObj;
}
