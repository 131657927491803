import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor, spacingTop, spacingBottom } from 'utils/meta';
import Service from 'utils/repo/service';

class GeneralVehicleComponent implements ContentBlock {
  label = 'General Vehicle Component';

  component = 'GeneralVehicleComponent';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    anchor,
    {
      name: 'title',
      label: 'General Vehicle Component Title',
      help: 'Enter the title for the General Vehicle displayed on the screen',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'selectedRentalService',
      label: 'Selected Rental Service (Required)',
      help: 'Selected Rental Service (Required)',
      type: 'picker',
      src: 'RentalServices',
      control: 'input:text',
      required: true,
      defaultValue: ''
    },
    spacingTop,
    spacingBottom
  ];

  properties = [
    {
      name: 'title',
      label: 'Legal Text Title',
      help: 'The title of the content legal text',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'description',
      label: 'Legal Text Content',
      help: 'The content of the legal text',
      type: 'String',
      control: 'editor',
      defaultValue: '',
      props: null
    }
  ];

  async adapter(contents: any[], extra: any, dataFetcher: any): Promise<object> {
    const productId = extra?.context?.productId;
    const selectedRentalService = extra?.settings?.selectedRentalService || null;
    const isAllServicesRental = selectedRentalService === 'GeneralServicesRental';
    try {
      const query = isAllServicesRental ? Service.getRentalServiceProductIdVehicles() : Service.getRentalServiceId();

      const variables = isAllServicesRental
        ? { productId: productId }
        : { id: selectedRentalService, productId: productId };

      const response = await dataFetcher(query, variables);

      const rentalServicesData = isAllServicesRental ? response?.data?.rentalServices : response?.data?.rentalService;

      if (!rentalServicesData) {
        throw new Error('No rental services were found');
      }

      const getVehiclesByRentalServicesData = isAllServicesRental
        ? this.formatAllServicesRentalData(rentalServicesData)
        : this.formatSelectedRentalServiceData(rentalServicesData);

      return {
        carCategoriesData: getVehiclesByRentalServicesData,
        locale: extra?.context?.locale,
        settings: extra?.settings
      };
    } catch (err) {
      console.error('Falha ao buscar dados do componente TabsWrapper', err);
    }

    return { carCategoriesData: [] };
  }

  private formatAllServicesRentalData(rentalServicesData: any): any[] {
    const data = this.joinData(this.getOrderedData(rentalServicesData, ['order']));

    return data?.map((service: any) => {
      const orderedVehicles = this.getOrderedVehiclesByCategory(service?.rentalVehicles);

      return {
        id: service?.id,
        name: service?.name,
        service: service?.name,
        legalText: service?.legalText,
        models: orderedVehicles.map((vehicle: any) => this.formatVehicleData(vehicle))
      };
    });
  }

  private formatSelectedRentalServiceData(rentalServicesData: any): any {
    const serviceLegalText = rentalServicesData?.legalText || null;
    const orderedVehicles = this.getOrderedVehiclesByCategory(rentalServicesData.rentalVehicles);
    const categories = {};

    orderedVehicles.forEach((vehicle) => {
      const model = this.formatVehicleData(vehicle);

      // eslint-disable-next-line no-prototype-builtins
      if (categories.hasOwnProperty(vehicle.fullCategory.id)) {
        categories[vehicle.fullCategory.id].models.push(model);
      } else {
        categories[vehicle.fullCategory.id] = {
          id: vehicle.fullCategory.id,
          name: vehicle.fullCategory.name,
          service: rentalServicesData.name,
          models: [model]
        };
      }
    });

    const formattedData = Object.values(categories).map((category: any) => ({
      id: category?.id,
      name: category.name,
      models: category.models,
      service: category?.service,
      legalText: serviceLegalText
    }));

    return formattedData;
  }

  private formatVehicleData(vehicle: any): any {
    return {
      categoryId: vehicle.fullCategory.id,
      name: vehicle.name,
      id: vehicle.vehicleModelYear.id,
      prices: vehicle.prices.length > 0 ? vehicle.prices : null,
      mainVersion: {
        name: vehicle?.vehicleModelYear?.version?.name,
        available: vehicle?.vehicleModelYear?.availableForKinto || false,
        specs: vehicle?.vehicleModelYear?.specs,
        medias: vehicle.vehicleModelYear.medias.map((media: any) => ({
          tag: media.tag,
          media: {
            title: media.media.title,
            url: media.media.url,
            mediaType: {
              title: media.media.mediaType.title
            }
          }
        }))
      }
    };
  }

  private getOrderedVehicles(rentalVehicles) {
    const vehiclesOrdered = this.getOrderedData(rentalVehicles, ['order']);

    vehiclesOrdered.forEach((value, key, map) => {
      if (value.length > 1) {
        const versionsOrdered = this.getOrderedData(value, ['vehicleModelYear', 'version', 'order']);
        map.set(key, this.joinData(versionsOrdered));
      }
    });

    return this.joinData(vehiclesOrdered);
  }

  private getOrderedVehiclesByCategory(rentalVehicles) {
    const orderedVehicles = this.getOrderedVehicles(rentalVehicles);
    const orderedVehiclesByCategory = this.joinData(this.getOrderedData(orderedVehicles, ['fullCategory','order']));

    return orderedVehiclesByCategory;
  }

  private getOrderedData(data, keys) {
    const dataMap = new Map();
    const dataOrder = [];
    const dataOrdered = new Map();

    data.forEach((value) => {
      const order = this.getObjectValue(value, keys);
      let mapValue;

      if (dataMap.has(order)) {
        mapValue = dataMap.get(order);
        mapValue = [...mapValue, value];
      } else {
        mapValue = [value];
        dataOrder.push(order);
      }

      dataMap.set(order, mapValue);
    });

    dataOrder.sort(function (a, b) {
      return a - b;
    });

    dataOrder.forEach((order) => {
      dataOrdered.set(order, dataMap.get(order));
    });

    return dataOrdered;
  }

  private joinData(data){
    return [].concat(...data.values());
  }

  private getObjectValue(object, keys) {
    return keys.reduce((accumulator, currentValue) => accumulator && accumulator[currentValue], object);
  }
}

export { GeneralVehicleComponent };
