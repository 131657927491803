import React from 'react';
import TabsWrapper from 'components/__organisms/tabs-wrapper';
// loading the sass style fot the component
import css from './styles.module.scss';

function GeneralVehicleComponent(props) {
  const { className = '', carCategoriesData = null, settings = null, locale = null, ...other } = props;

  return (
    <TabsWrapper
      className={`${css['general-vehicle-component-container']} ${className}`}
      {...other}
      carCategoriesData={carCategoriesData}
      locale={locale}
      settings={settings}
    />
  );
}

export default GeneralVehicleComponent;
