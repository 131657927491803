import React, { useState, useEffect } from 'react';

import AnchorLink from 'components/__atoms/anchor-link';
import FormSelect from 'components/__atoms/form-select';
import Section from 'components/__atoms/section';
import Title from 'components/__atoms/title';
import Wrapper from 'components/__atoms/wrapper';
import { IconPinMap } from 'components/__icons';
import MapInformation from 'components/__molecules/map-information';

import { useForm } from 'react-hook-form';
import { updateURLParameter } from 'utils/forms';
import i18n from 'utils/i18n';
import css from './styles.module.scss';

function FindDealers(props) {
  const t = i18n.useTranslations('components.find-dealers');

  const {
    className = '',
    dealers = [],
    listStates,
    spacingTop = 6,
    spacingBottom = 6,
    locale,
    bgColor = 'gray',
    title,
    description,
    ...other
  } = props;

  const { register, setValue, watch } = useForm({
    defaultValues: {
      state: 'placeholder',
      city: 'placeholder'
    }
  });

  const mapZoom = 1;
  const [markers, setMarkers] = useState([]);
  const [citiesFromStates, updateCitiesFromStates] = useState([]);
  const [goToCurrentPosition, setGoToCurrentPosition] = useState(null);
  const [locationError, setLocationError] = useState(null);
  const [loadingLocation, setLoadingLocation] = useState(false);

  const watchCity = watch('city');
  const watchState = watch('state');

  useEffect(() => {
    const url = new URL(location.href);
    const params = url.searchParams;

    const state = params.get('state');
    const city = params.get('city');

    if (state !== null && state !== '') {
      setValue('state', state.toString());

      if (city !== null && city !== '') {
        setValue('city', city.toString());
      } else {
        const dealersFromState = dealers?.filter(
          (dealer) => dealer?.address?.state.toUpperCase() === state.toUpperCase()
        );
        setMarkers(dealersFromState);
      }
    } else {
      setMarkers(dealers);
    }
  }, []);

  useEffect(() => {
    if (watchState && watchState !== 'placeholder') {
      updateURLParameter('state', watchState);
      loadCitiesList();
    }
  }, [watchState]);

  useEffect(() => {
    if (watchCity && watchCity !== 'placeholder') {
      updateURLParameter('city', watchCity);
      const dealersFromCity = dealers?.filter(
        (dealer) =>
          dealer?.address?.state.toUpperCase() === watchState?.toUpperCase() && dealer?.address?.city === watchCity
      );
      setMarkers(dealersFromCity);
    } else {
      updateURLParameter('city', null);
    }
  }, [watchCity]);

  function loadCitiesList() {
    const dealersFromState = dealers?.filter(
      (dealer) => dealer?.address?.state.toUpperCase() === watchState?.toUpperCase()
    );

    if (watchState !== 'placeholder') {
      setMarkers(dealersFromState);
    }

    const uniqueCitiesList = Array.from(
      new Set(dealersFromState.map((dealer) => dealer.address.city.toUpperCase()))
    ).sort();

    updateCitiesFromStates(uniqueCitiesList);
  }

  function onChangeState(event) {
    const stateValue = event.target.value;
    setValue('city', 'placeholder');
    updateURLParameter('city', null);
    setValue('state', stateValue.toString());
  }

  function onChangeCity(event) {
    const cityValue = event.target.value;
    setValue('city', cityValue.toString());
  }

  const handleUseMyLocation = async () => {
    setLoadingLocation(true);

    try {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setGoToCurrentPosition({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            });
          },
          (error) => {
            setLocationError(t('unable_to_access_location'));
            console.log(error);
          }
        );
      } else {
        setLocationError(t('message_geolocation_not_supported'));
      }
    } catch (error) {
      console.error('Error getting user location:', error);
    } finally {
      setLoadingLocation(false);
    }
  };

  useEffect(() => {
    let timer;

    if (locationError) {
      timer = setTimeout(() => {
        setLocationError(null);
      }, 8000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [locationError]);

  return (
    <Wrapper
      bgColor={bgColor}
      spacingTop={spacingTop}
      spacingBottom={spacingBottom}
      className={`${css['find-dealers-container']} ${className}`}
      {...other}>
      <Section tag="div" className={css['form-container']}>
        <div className={css['form-container-header']}>
          <Title className={css['form-header-title']} level={1} variant={2} color="primary">
            {title || t('header_title')}
          </Title>
          <span className={css['form-header-description']}>{description || t('header_description')}</span>
        </div>

        <div className={css['filter-form']}>
          <FormSelect
            displayLabel={false}
            displayError={false}
            id="state"
            placeholder={t('placeholder_state')}
            className={`${css['select-input']} ${watchState === 'placeholder' && css['placeholder-selected']}`}
            dataRegister={register('state')}
            onChange={onChangeState}>
            {listStates?.length > 0 ? (
              listStates?.map((state, index) => {
                return (
                  <option key={index} value={state.toUpperCase()}>
                    {state.toUpperCase()}
                  </option>
                );
              })
            ) : (
              <option>{t('placeholder_state')}</option>
            )}
          </FormSelect>
          <FormSelect
            displayLabel={false}
            displayError={false}
            id="city"
            placeholder={t('placeholder_city')}
            className={`${css['select-input']} ${watchCity === 'placeholder' && css['placeholder-selected']}`}
            dataRegister={register('city')}
            onChange={onChangeCity}>
            {citiesFromStates.length > 0 ? (
              citiesFromStates?.map((city, index) => {
                return (
                  <option key={index} value={city}>
                    {city}
                  </option>
                );
              })
            ) : (
              <option>{t('placeholder_city')}</option>
            )}
          </FormSelect>
        </div>

        <AnchorLink
          size="small"
          hasArrow={false}
          color="primary"
          onClick={handleUseMyLocation}
          linkLabel={
            <>
              {t('use_my_location')} <IconPinMap />
            </>
          }
        />
        {loadingLocation && (
          <div className={`loading-indicator ${css['loading-indicator']}`}>{t('loading_my_location')}</div>
        )}
      </Section>

      <MapInformation
        data-id={'map'}
        locale={locale}
        markers={markers}
        selectedCity={watchCity}
        selectedState={watchState}
        selectedZoom={mapZoom}
        centerOffset={-0.012}
        goToCurrentPosition={goToCurrentPosition}
      />
      {locationError && (
        <span id="toast" className={css[locationError ? 'toast' : 'hidden']}>
          {locationError}
        </span>
      )}
    </Wrapper>
  );
}

export default FindDealers;
