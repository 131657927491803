import React from 'react';

// loading the sass style fot the component
import css from './styles.module.scss';

function Tab(props) {
  const { className = '', selected = false, id, onClick = null, children, link = null, ...other } = props;

  function handleClick() {
    if (id && onClick) {
      onClick(id);
    }
  }

  const Tag = link ? 'a' : 'button';

  return (
    <Tag
      className={`${css['atom__tab-container']} ${className} ${selected ? css['selected'] : ''}`}
      onClick={onClick !== null ? handleClick : undefined}
      href={link || undefined}
      {...other}>
      {children}
    </Tag>
  );
}

export default Tab;
