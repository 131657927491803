import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor, spacingBottom, spacingTop } from 'utils/meta';
class BackToTop implements ContentBlock {
  label = 'Back to top';

  component = 'BackToTop';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = true;

  settings = [
    {
      name: 'bgColor',
      label: 'Wrapper backgroud color',
      help: '',
      type: 'String',
      control: 'select',
      options: [
        'default',
        'gray',
        'gray-2', 'gray-3', 'gray-4', 'gray-5', 'gray-6',
        'black'
      ],
    },
    spacingTop,
    spacingBottom,
    anchor
  ];

  properties = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async adapter (contents: any[], extra: any): Promise<object> {
    return {
      bgColor: extra?.settings?.bgColor,
      spacingTop: extra?.settings?.spacingTop,
      spacingBottom: extra?.settings?.spacingBottom
    };
  }
}

export { BackToTop };
