export const captchaToken = async (action, key) => {
  if (typeof window === 'undefined') {
    return false;
  }

  if (typeof grecaptcha === 'undefined') {
    throw Error('Google Recaptcha is not available');
  }

  const token = await grecaptcha.enterprise?.execute(key, {
    action: action
  });

  return {
    token,
    action: action
  };
};
