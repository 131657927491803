import React from 'react';
import Tab from '../../__atoms/tab';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule CategoryTabs
 *
 * <!-- TODO: add a description here! -->
 */

function CategoryTabs(props) {
  const {
    className = '',
    children,
    tabs = [],
    selected = null,
    onChange = () => {},
    centered = false,
    removeDesktopCarousel = false,
    ...other
  } = props;

  const sliderSettings = {
    speed: 500,
    useTransform: false,
    arrows: true,
    dots: false,
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: removeDesktopCarousel ? 9999 : null,
        settings: 'unslick'
      },
      {
        breakpoint: 600, //$bp-phone
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  try {
    const [selectedTab, setSelectedTab] = React.useState(selected ? selected : tabs?.length > 0 ? tabs[0]?.id : null);

    const uniqueTabs = new Set();

    const handleOnClick = (id) => {
      onChange(id);
      setSelectedTab(id);
    };

    return (
      <div className={`${css['molecule__category-tabs-container']} ${className}`} {...other}>
        <div className={`${css['tabs-container']} ${centered ? css['centered'] : ''}`}>
          {isEmpty(tabs) ? (
            'Category Tabs component missing Tabs'
          ) : (
            <Slider {...sliderSettings} className={`${css['action-slider-wrapper']} ${className}`} {...other}>
              {tabs
                .filter((tab) => {
                  if (uniqueTabs.has(tab.id)) {
                    return false;
                  }
                  uniqueTabs.add(tab.id);
                  return true;
                })
                .map((tab) => {
                  return (
                    <div key={`${tab?.id}-cont`} className={css['slide-container']}>
                      <Tab
                        className={css['slide-container-tab']}
                        data-testid={`tab-${tab.id}`}
                        key={tab?.id}
                        id={tab?.id}
                        onClick={handleOnClick}
                        link={tab?.link}
                        selected={tab?.id === selectedTab}>
                        {tab?.label}
                      </Tab>
                    </div>
                  );
                })}
            </Slider>
          )}
        </div>

        {children}
      </div>
    );
  } catch (error) {
    console.error('Category Tabs component error: ', error);
    return <div>There was an error in the Category Tabs</div>;
  }
}

export default CategoryTabs;
