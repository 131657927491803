import { anchor, bgColor, spacingTop, spacingBottom } from 'utils/meta';
import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';

import Dealer from 'utils/repo/dealers';

import Service from 'utils/repo/service';

class FindDealers implements ContentBlock {
  label = 'Dealers list';

  component = 'FindDealers';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    anchor,
    {
      name: 'title',
      label: 'Dealers list Title',
      help: 'Enter the title for the Dealers list displayed on the screen',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'description',
      label: 'Dealers List Description',
      help: 'Provide a description for the dealers list displayed on the screen',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    spacingTop,
    spacingBottom,
    bgColor
  ];

  properties = [];

  async adapter(contents: any[], extra: any, dataFetcher: any): Promise<object> {
    const productId = extra?.context?.productId;
    const locale = extra?.context?.locale;

    const rentalServicesByProductId = (await dataFetcher(Service.getRentalServiceProductId(), { productId: productId }))
      ?.data?.rentalServices;

    if (!rentalServicesByProductId) {
      throw new Error('No rental services were found');
    }

    const dealersByLocation = (await dataFetcher(Dealer.getAllDealersAddress(), { productId: productId }))?.data
      ?.dealers;

    if (!dealersByLocation) {
      throw new Error('No dealers were found');
    }

    const dealers = [];

    const listRentalServices = rentalServicesByProductId || [];

    dealersByLocation.forEach((dealer) => {
      dealer?.addresses?.forEach((address) => {
        const isMainAddress = address.mainAddress;
        const product = dealer?.dealerProducts.find((product) => product?.productId === productId);

        const dealerData = {
          address: { ...address },
          position: { lat: parseFloat(address?.coordLat), lng: address?.coordLng },
          id: dealer?.id,
          idDealerTasa: dealer?.idDealerTasa,
          contactEmail: isMainAddress && product ? product?.attributes?.email : null,
          phone: isMainAddress && product ? product?.attributes?.phone : null,
          whatsapp: isMainAddress && product ? product?.attributes?.mobile : null,
          openHour: (isMainAddress && product && product?.attributes?.openHour) || address?.openHour || null,
          name: isMainAddress ? dealer?.name : address?.name,
          companyName: dealer?.companyName,
          urlToyota: isMainAddress && dealer?.urlToyota,
          site: isMainAddress && dealer?.site,
          welcome: isMainAddress && dealer?.welcome,
          mainAddress: isMainAddress,
          rentalServices: isMainAddress
            ? listRentalServices
            : listRentalServices.filter((service) => /share/i.test(service?.name))
        };

        dealers.push(dealerData);
      });
    });
    const listStates = [...new Set(dealers.map((state) => state?.address?.state))].sort();

    return {
      bgColor: extra?.settings?.bgColor,
      dealers: dealers,
      description: extra?.settings?.description,
      listStates: listStates,
      locale: locale || 'pt-BR',
      spacingBottom: extra?.settings?.spacingBottom,
      spacingTop: extra?.settings?.spacingTop,
      title: extra?.settings?.title
    };
  }
}

export { FindDealers };
