const findAll = () => {
  return `
    query servicesByLanguage($locale: String!) {
      services (locale: $locale) {
        id
        title
        content {
          icon {
            url
            title
          }
        }
        page {
          id
          fullpath
        }
      }
    }`;
};

const findByPage = () => {
  return `query service($pageId: String!) {
    serviceByPage (pageId: $pageId) {
      id
      title
      pageId
      description
      content {
        icon {
          url
          title
        }
        description
        media {
          url
          title
          mediaType{
            title
          }
        }
      }
      page {
        fullpath
      }
    }
  }`;
};

const getServiceItemCategoriesByPage = () => {
  return `query service($pageId: String!) {
    serviceByPage (pageId: $pageId) {
      serviceItemCategories {
        id
        title
        media {
          url
          title
          mediaType {
            title
          }
        }
        service {
          id
        }
        serviceItems {
          id
          title
          subtitle
          description
          contents {
            title
            description
          }
          price {
            value
            endValidity
            startValidity
            installment {
              quantity
              value
            }
          }
        }
      }
    }
  }`;
};

const getRentalServiceProductIdForForm = () => {
  return `query rentalServices ($productId: String!) {
    rentalServices (productId: $productId) {
      id
      name
    }
  }`;
};

const getRentalServiceProductId = () => {
  return `query rentalServices ($productId: String!) {
    rentalServices (productId: $productId) {
      id
      name
      description
      url
      logo {
        id
        url
        title
      }
    }
  }`;
};

const getRentalServiceProductIdVehicles = () => {
  return `query rentalServices ($productId: String!) {
    rentalServices (productId: $productId) {
      id
      name
      legalText
      order
      rentalVehicles {
        name
        order
        fullCategory {
          id
          name
          order
        }
        vehicleModelYear {
          id
          availableForKinto
          specs(productId: $productId) {
            id
            name
            contents {
              title
              description
              icon {
                url
                path
                title
              }
            }
          }
          clientVehicleId
          version {
            id
            name
            order
          }
          medias {
            tag
            id
            media {
              id
              url
              title
              mediaType {
                title
              }
            }
          }
        }
        prices {
          value
          interval
          startValidity
          endValidity
          franchise
          airportCharge
          package {
            value
          }
        }
      }
    }
  }`;
};

const getRentalServiceId = () => {
  return `query rentalService ($id: String!, $productId: String!) {
    rentalService (id: $id) {
      id
      name
      legalText
      order
      rentalVehicles {
        name
        order
        fullCategory {
          id
          name
          order
        }
        vehicleModelYear {
          id
          availableForKinto
          specs(productId: $productId) {
            id
            name
            contents {
              title
              description
              icon {
                url
                path
                title
              }
            }
          }
          clientVehicleId
          version {
            id
            name
            order
          }
          medias {
            tag
            id
            media {
              id
              url
              title
              mediaType {
                title
              }
            }
          }
        }
        prices {
          value
          interval
          startValidity
          endValidity
          franchise
          airportCharge
          package {
            value
          }
        }
      }
    }
  }`;
};

const Service = {
  getServiceItemCategoriesByPage,
  findAll,
  findByPage,
  getRentalServiceProductId,
  getRentalServiceProductIdForForm,
  getRentalServiceProductIdVehicles,
  getRentalServiceId
};

export default Service;
