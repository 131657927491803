import { ContentBlockSetting } from 'components/content-blocks/ContentBlockSetting';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import Page from 'utils/repo/page';
import Template from './Template';

class PageTemplate implements Template {
  private readonly blocks = [];

  private readonly blockBuilder;

  constructor(blocks, blockBuilder) {
    this.blockBuilder = blockBuilder;
    this.blocks = blocks;
  }

  label = 'Page';
  logKey = 'TEMPLATE:PAGE';
  type = 'Page';

  settings = [
    {
      name: 'datePublished',
      label: 'datePublished',
      help: 'datePublished-help',
      type: 'date'
      // control: 'toggle',
      // defaultValue: true
    },
    {
      name: 'socialImage',
      label: 'socialImage',
      help: 'socialImage-help',
      type: 'image'
      // control: 'toggle',
      // defaultValue: true
    }
  ];

  public sections(data) {
    if (!data?.sectionsByPage) {
      return [];
    }

    return data?.sectionsByPage;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async build(pageData, context, dataFetcher): Promise<any[]> {

    if (!context?.locale) {
      this.throwError(`There is no locale defined.`);
    }

    const pageId = context?.page?.id;
    if (!pageId) {
      this.throwError(`There is no page.id defined.`);
    }

    const content = [];

    for (const section of this.sections(pageData.data)) {
      const cb = this.blockBuilder(section.component, this.blocks);
      const settings = this.blockSettings(cb?.settings, section?.componentData);

      let blockData = section.contents;
      if (cb.type === ContentBlockType.ACTIVE) {
        const queryCb = await cb.queryBuilder(context);
        blockData = await dataFetcher(String(queryCb));
      }

      // using await, so meta adapter can actually perform async tasks too
      const data = await cb.adapter(
        blockData,
        {
          settings: settings,
          context: context,
          section: section
        },
        dataFetcher
      );

      content.push({
        component: cb.component,
        props: { ...data, ...settings, fullComponentName: section.component }
      });
    }

    return content;
  }

  /**
   * Build the query to retrive all sections and content needed
   * to render a page
   *
   * @returns string
   */
  public queryBuilder(): string {
    return Page.findPageSectionsByPageId();
  }

  /**
   * Transform database stored settings to Component required structure
   *
   * @param settings Settings structure of BlockComponent
   * @param values Values from database structure
   * @returns object with properties expected by Component
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected blockSettings(settings: ContentBlockSetting[], values: any[]): object {
    if (!settings || !values) {
      return {};
    }

    const props = {};
    settings.forEach((setting) => {
      props[setting.name] = setting?.defaultValue;
      if (typeof values[setting.name] !== 'undefined') {
        props[setting.name] = values[setting.name];
      }
    });

    return props;
  }

  protected buildBreadcrumbBlock(context) {
    if (!context?.params?.breadcrumb) {
      this.throwError(`Breadcrumb isn't available at PageTemplate`);
    }

    return {
      component: 'BreadcrumbList',
      props: {
        linkLabel: context?.i18nMessages?.main_navigation?.previous_page || 'Voltar a página anterior',
        link: context?.params?.breadcrumb?.at?.(-2) || '/',
        breadcrumbList: context?.params?.breadcrumb?.map((item) => {
          return {
            link: item.slug,
            linkLabel: item.name
          };
        })
      }
    };
  }

  protected buildActionBannerItemBlock(service) {
    return {
      component: 'ActionBannerItem',
      props: {
        bannerImage: {
          desktop: service?.content?.media?.url,
          mobile: service?.content?.media?.url,
          alt: service?.content?.media?.title
        },
        containerSize: 'medium',
        removeMask: true
      }
    };
  }

  protected logMessage(message) {
    return `[${this.logKey}] ${message}`;
  }

  protected throwError(message) {
    message = `[${this.logKey}] ${message}`;

    throw new Error(message);
  }
}

export default PageTemplate;
