import React, { useEffect, useState } from 'react';
import CategoryTabs from 'components/__molecules/category-tabs';
import TabCarCarousel from 'components/__molecules/tab-car-carousel';

import Section from 'components/__atoms/section';
import Title from 'components/__atoms/title';
import Wrapper from 'components/__atoms/wrapper';

import { applyRichTextShortcodes } from 'utils/richText';
import { dateIso2Br } from 'utils/functions';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

// loading the sass style fot the component
import css from './styles.module.scss';

function TabsWrapper(props) {
  const { className = '', carCategoriesData = null, locale, settings = null, ...other } = props;

  const [firstStartDate, setFirstStartDate] = useState(null);
  const [firstEndDate, setFirstEndDate] = useState(null);

  const initialData = mountCarCategoriesData();

  const [selectedTab, setSelectedTab] = useState(initialData.tabs?.[0]?.id);
  const [carCategoriesTabs, setCarCategoriesTabs] = useState(initialData.tabs);
  const [carCategoriesContent, setCarCategoriesContent] = useState(initialData.contents);

  useEffect(() => {
    if (carCategoriesData && !carCategoriesTabs) {
      const initialData = mountCarCategoriesData();
      setCarCategoriesTabs(initialData.tabs);
      setCarCategoriesContent(initialData.contents);
    }
  }, [carCategoriesData]);

  function mountCarCategoriesData() {
    const tabs = [];
    const contents = [];

    carCategoriesData?.forEach((category) => {
      const modelContents = [];
      const hasAvailableModels = category?.models?.some((model) => model?.mainVersion?.available);
      category?.models?.forEach((model) => {
        const medias = model?.mainVersion?.medias;
        const mediaRegular = medias?.find((media) => media?.tag?.includes(':regular'));
        const mediaHover = medias?.find((media) => media?.tag?.includes(':hover'));

        if (model?.mainVersion?.available) {
          const firstPrice = model?.prices?.find(() => true);
          if (firstPrice) {
            const startDate = firstPrice.startValidity;
            const endDate = firstPrice.endValidity;

            if (!firstStartDate) {
              setFirstStartDate(startDate);
            }

            if (!firstEndDate) {
              setFirstEndDate(endDate);
            }
          }

          modelContents.push({
            carlabel: model?.name,
            id: model?.id,
            prices: model?.prices,
            specs: model?.mainVersion?.specs,
            image: [
              {
                url: mediaRegular?.media?.url || null,
                alt: mediaRegular?.media?.title || model?.name,
                type: mediaRegular?.media?.mediaType?.title || null
              },
              {
                url: mediaHover?.media?.url || null,
                alt: mediaHover?.media?.title || model?.name,
                type: mediaHover?.media?.mediaType?.title || null
              }
            ]
          });
        }
      });

      if (hasAvailableModels && modelContents.length > 0) {
        tabs.push({
          id: category?.id,
          label: category?.name
        });

        const legalTextContent = category?.legalText || carCategoriesData?.legalText || null;
        contents.push({ id: category.id, content: modelContents, legalText: legalTextContent, service: category?.service });
      }
    });

    return { tabs, contents };
  }

  function updateTab(id) {
    if (selectedTab !== id) {
      carCategoriesContent?.find((data) => data?.id === id && data?.content?.length > 0) ? setSelectedTab(id) : null;
    }
  }

  return (
    <Wrapper spacingTop={settings?.spacingTop} spacingBottom={settings?.spacingBottom} {...other}>
      <Section>
        {settings?.title && (
          <Title className={css['title']} level={2} variant={2}>
            {settings?.title}
          </Title>
        )}
        <div className={`${css['organism__tabs-wrapper-container']} ${className}`}>
          {carCategoriesTabs && (
            <CategoryTabs
              tabs={carCategoriesTabs}
              className={css['tabs-wrapper']}
              centered={true}
              onChange={updateTab}
              removeDesktopCarousel={true}
            />
          )}

          {carCategoriesContent &&
            selectedTab &&
            carCategoriesContent?.map((tabContent) => {
              if (tabContent?.id === selectedTab) {
                return (
                  <React.Fragment key={tabContent.id}>
                    <TabCarCarousel
                      content={tabContent?.content}
                      service={tabContent?.service}
                      className={selectedTab === tabContent?.id ? null : css['hidden']}
                      locale={locale}
                    />

                    {tabContent?.legalText && firstStartDate && firstEndDate && (
                      <div className={css['modal-container']}>
                        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                          {applyRichTextShortcodes(
                            tabContent?.legalText
                              .replace('%STARTDATE%', dateIso2Br(firstStartDate) || '')
                              .replace('%ENDDATE%', dateIso2Br(firstEndDate) || '')
                          )}
                        </ReactMarkdown>
                      </div>
                    )}
                  </React.Fragment>
                );
              }
              return null;
            })}
        </div>
      </Section>
    </Wrapper>
  );
}

export default TabsWrapper;
