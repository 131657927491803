import React, { useEffect, useState } from 'react';
import i18n from '../../../utils/i18n';
import { phoneMask, phoneMaskDdg } from 'utils/validations';
import { capitalizeWords, formatClearPhoneNumber } from 'utils/functions';
import { IconGoogleMaps, IconWaze, IconWhatsApp } from '../../__icons';
import { useMediaQuery } from 'react-responsive';
import Button from '../../__atoms/button';
import Title from '../../__atoms/title';
import ContentDivider from '../../__atoms/content-divider';

// Carregando o estilo sass para o componente
import css from './styles.module.scss';

/**
 * Molecule InfoDealer
 */

function InfoDealer(props) {
  const t = i18n.useTranslations('components.info-dealer');

  const {
    className = '',
    dealer = {},
    handleInfoDealer,
    locale,
    urlWhats = t('url_whatsapp'),
    textWhats = t('text_whatsapp'),
    ...other
  } = props;

  const isTDB = locale?.toLowerCase() === 'pt-br';
  const isTASA = locale?.toLowerCase() === 'es-ar';
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const [userCoord, setUserCoord] = useState({ startLat: null, startLng: null });

  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;

          setUserCoord({ startLat: lat, startLng: lng });
        },
        function (error) {
          console.error(error.message);
        }
      );
    } else {
      console.error('Geolocation not supported by the browser.');
    }
  }, []);

  const formattedAddress = `${dealer?.address?.street || ''}
  ${dealer?.address?.number ? dealer?.address?.number + ' -' : ''}
  ${dealer?.address?.city?.[0]?.toUpperCase() + dealer?.address?.city?.slice(1).toLowerCase()},
  ${dealer?.address?.state?.[0]?.toUpperCase() + dealer?.address?.state?.slice(1).toLowerCase()}`;

  const wazeUrl = isMobile
    ? getWazeUrlMobile(formattedAddress)
    : getWazeUrlDesktop(userCoord?.startLat, userCoord?.startLng, dealer?.position?.lat, dealer?.position?.lng);

  function phoneMaskCondition(phone) {
    return phone.substring(0, 4) == '0800' ? phoneMaskDdg(phone) : phoneMask(phone);
  }

  function PhoneNumbersList() {
    const numbersArray = dealer?.phone.split(';');
    return numbersArray.map((number, index) => <p key={index}>{number}</p>);
  }

  function getWazeUrlMobile(formattedAddress) {
    const urlMobile = `https://www.waze.com/ul?q=${encodeURIComponent(formattedAddress)}`;

    return urlMobile;
  }

  function getWazeUrlDesktop(latUser, lngUser, latDealer, lngDealer) {
    const baseUrlByCountry = t('url_waze_by_country');

    return latUser && lngUser && latDealer && lngDealer
      ? `${baseUrlByCountry}?to=ll.${latDealer}%2C${lngDealer}&from=ll.${latUser}%2C${lngUser}`
      : latDealer && lngDealer
        ? `${baseUrlByCountry}?to=ll.${latDealer}%2C${lngDealer}`
        : baseUrlByCountry;
  }

  return (
    <div className={`${css['molecule__info-dealer-container']} ${className}`} {...other}>
      <div className={css['info-dealer']}>
        <div className={css['info-dealer-header']}>
          <Title variant={4} level={3} color="primary">
            {capitalizeWords(dealer?.name)}
          </Title>
        </div>
        <div className={css['info-dealer-container']}>
          <div className={css['info-dealer-container__address']}>
            <div>{formattedAddress}</div>
            {dealer?.mainAddress && (
              <>
                {dealer?.mainAddress && (dealer?.whatsapp || dealer?.contactEmail || dealer?.dealerPhone) && (
                  <Title className={css['info-dealer-container__address__phones__title']} color="primary">
                    {t('title_contact')}
                  </Title>
                )}
                {dealer?.phone &&
                  ((isTDB && <p>{phoneMaskCondition(dealer?.phone)}</p>) || (isTASA && PhoneNumbersList()))}
                {dealer?.whatsapp && (
                  <p className={css['info-dealer-container__address__cell-whatsap']}>
                    <span>
                      {isTDB && phoneMaskCondition(dealer?.whatsapp)}
                      {isTASA && dealer?.whatsapp}
                    </span>
                    <a
                      href={urlWhats.concat(formatClearPhoneNumber(dealer?.whatsapp)).concat(textWhats)}
                      aria-label="Whatsapp"
                      title="Whatsapp"
                      target="_blank">
                      <IconWhatsApp color="#00708d" className={css['info-dealer-icon-whatsapp']} />
                    </a>
                  </p>
                )}
                {dealer?.contactEmail && (
                  <p className={css['email-address-container']}>
                    <a
                      className={css['info-dealer-container__address__email']}
                      href={`mailto:${dealer?.contactEmail}`}
                      title={t('title_email')}
                      target="_blank">
                      {dealer?.contactEmail}
                    </a>
                  </p>
                )}
              </>
            )}
          </div>
          <div className={css['info-dealer-container__button']}>
            <Button color="primary" className={css['button']} onClick={handleInfoDealer}>
              {t('button_see_map')}
            </Button>
          </div>
        </div>
        {dealer?.openHour && (
          <>
            <ContentDivider size="tiny" />
            <div className={css['info-dealer-container__address']}>
              {dealer?.openHour.split(';').map((item, index) => (
                <p key={index}>{item?.trim()}</p>
              ))}
            </div>
          </>
        )}
        {dealer?.rentalServices?.length > 0 && (
          <>
            <ContentDivider size="tiny" />
            <div className={css['service-container']}>
              {dealer?.rentalServices?.map((service) => (
                <li key={service?.id} className={css['service']}>
                  {service?.logo?.url && (
                    <img
                      src={service?.logo?.url}
                      alt={service?.logo?.title || 'Logo'}
                      className={css['service-logo']}
                    />
                  )}
                  {service?.description &&
                    (service?.url ? (
                      <a className={css['service-link']} href={service?.url} title={service?.name} target="_blank">
                        {service?.description}
                      </a>
                    ) : (
                      <span className={css['service-span']}>{service?.description}</span>
                    ))}
                </li>
              ))}
            </div>
          </>
        )}
        <ContentDivider size="tiny" />
        <div className={css['maps-links-container']}>
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(formattedAddress)}`}
            target="_blank"
            rel="noopener noreferrer"
            className={css['maps-link']}>
            <IconGoogleMaps className={css['maps-links-icon']} />
            <span>{t('label-link-google-maps')}</span>
          </a>
          <a href={wazeUrl} target="_blank" rel="noopener noreferrer" className={css['maps-link']}>
            <IconWaze className={css['maps-links-icon']} />
            <span>{t('label-link-waze')}</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default InfoDealer;
