/**
 * Content Block can have some types defined by
 * this enum.
 */
export enum ContentBlockType {
  /**
   * Receive all needed data to hydrate it
   */
  PASSIVE = 1,
  /**
   * Don't need any input data, it tell how to
   * retrieve necessary informations
   */
  ACTIVE,
}
