import React from 'react';
import i18n from 'utils/i18n';

import Wrapper from 'components/__atoms/wrapper';
import Section from 'components/__atoms/section';
import AnchorLink from 'components/__atoms/anchor-link';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule BackToTop
 *
 * Back to top of the site button
 */
function BackToTop (props) {
  const t = i18n.useTranslations('components.back-to-top');

  const {
    className = "",
    spacingTop = 4,
    spacingBottom = 4,
    bgColor = null,
    linkLabel = t('back-to-top'),
    ...other
  } = props;

  return <>
    <Wrapper spacingTop={spacingTop} spacingBottom={spacingBottom} bgColor={bgColor} {...other}>
      <Section className={`${css["back-to-top-container"]} ${className}`} tag="div">
        <AnchorLink
          linkLabel={linkLabel}
          arrowPosition="up"
          data-uppercase
          removeUnderline
          link="#main"
        />
      </Section>
    </Wrapper>
  </>;
}

export default BackToTop;
