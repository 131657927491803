/**
 * GENERAL
 */
export const showTitle = {
  name: 'showTitle',
  label: 'Show section title',
  help: 'Will show the section title in the top of the section',
  type: 'boolean',
  control: 'toggle',
  defaultValue: true
};

export const anchor = {
  name: 'anchor',
  label: 'Navigation Anchor',
  help: 'Enter the navigation for anchor.',
  type: 'String',
  control: 'input:text',
  defaultValue: ''
};

export const spacingTop = {
  name: 'spacingTop',
  label: 'The padding space at the top of the section',
  help: '',
  type: 'String',
  control: 'select',
  options: [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7'
  ]
};

export const spacingBottom = {
  name: 'spacingBottom',
  label: 'The padding space at the bottom of the section',
  help: '',
  type: 'String',
  control: 'select',
  options: [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7'
  ]
};

export const bgColor = {
  name: 'bgColor',
  label: 'Wrapper backgroud color',
  help: '',
  type: 'String',
  control: 'select',
  options: [
    'default',
    'white',
    'black',
    'gray',
    'gray-2',
    'gray-3',
    'gray-4',
    'gray-5',
    'gray-6',
    'gray-7',
    'blue-1',
    'blue-2'
  ]
};

export const centerContent = {
  name: 'centerContent',
  label: 'Center the content of the section?',
  help: 'Center all elements inside the section',
  type: 'boolean',
  control: 'toggle',
  defaultValue: false
};

export const kindOfContent = {
  name: 'kindOfContent',
  label: 'Kind of Content',
  help: 'A field to identify the section to which each return belongs',
  type: 'text',
  control: 'input:text',
  defaultValue: ''
};

/**
 * FORM
 */
export const formTitle = {
  name: 'title',
  label: 'Title form',
  help: 'The title of the form',
  type: 'String',
  control: 'input:text',
  defaultValue: ''
};

export const formDescription = {
  name: 'description',
  label: 'Description',
  help: 'Is the description of the form',
  type: 'String',
  control: 'input:text',
  defaultValue: ''
};

export const  formHeaderSpacing = {
  name: 'headerSpacing',
  label: 'Header spacing',
  help: 'Is the spacing between the header with title and description of the form',
  type: 'String',
  control: 'select',
  options: [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7'
  ]
};

export const formAttractingMedia = {
  name: 'attractingMedia',
  label: 'Attracting Media',
  help: 'Indentify the form',
  type: 'String',
  control: 'input:text',
  defaultValue: 'SITE MONTADORA'
};

export const formTitleSuccess = {
  name: 'titleSuccess',
  label: 'Title success',
  help: 'The title used in the sent form success response modal',
  type: 'String',
  control: 'input:text',
  defaultValue: ''
};

export const formContentSuccess = {
  name: 'contentSuccess',
  label: 'Content success',
  help: 'Descriptive text used in the sent form success response modal',
  type: 'String',
  control: 'input:text',
  defaultValue: ''
};

export const formLinkSuccess = {
  name: 'linkSuccess',
  label: 'Link success',
  help: 'The link used in the sent form success response modal',
  type: 'String',
  control: 'input:text',
  defaultValue: ''
};

export const formLinkTitleSuccess = {
  name: 'linkTitleSuccess',
  label: 'Link title success',
  help: 'The title of the link used in the sent form success response modal',
  type: 'String',
  control: 'input:text',
  defaultValue: ''
};

export const formTitleError = {
  name: 'titleError',
  label: 'Title error',
  help: 'The title used in the sent form error response modal',
  type: 'String',
  control: 'input:text',
  defaultValue: ''
};

export const formContentError = {
  name: 'contentError',
  label: 'Content error',
  help: 'Descriptive text used in the sent form error response modal',
  type: 'String',
  control: 'input:text',
  defaultValue: ''
};
