import React from 'react';
// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Atom FormCheckbox
 *
 * <!-- TODO: add a description here! -->
 */
function FormCheckbox(props) {

  const {
    className = "",
    id,
    disabled,
    label,
    dataRegister,
    dataErrors,
    displayError = true,
    children,
    ...other
  } = props;

  return <div
    className={`${css["atom__form-checkbox-container"]} ${css["ff-container"]} ${className}`}
  >
    <label
      htmlFor={id}
      className={`${css["ff-label"]} ${displayError ? '' : css['ff-label-clean']} ${disabled ? css["ff-disabled"] : ""}`}
    >
      <input
        id={id}
        type="checkbox"
        className={`${css["field-input"]} ${dataErrors?.type ? css["ff-box-error"] : ""}`}
        {...dataRegister}
        {...other}
        disabled={disabled ? true : undefined}
      >
      </input>
      <span className={`${css["checkmark"]}`}></span>
      {label ? label : children}
    </label>
    {displayError && dataErrors?.type && <span className={css["ff-error-message-checkbox"]}> {dataErrors.message} </span>}
  </div>;
}

export default FormCheckbox;
