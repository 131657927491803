import { anchor, formContentError, formContentSuccess, formDescription,
  formTitle, formTitleError, formTitleSuccess, spacingBottom, spacingTop } from '@/utils/meta';
import Vehicle from '@/utils/repo/vehicle';
import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';

import Dealers from 'utils/repo/dealers';
import Service from 'utils/repo/service';

class ContactForm implements ContentBlock {
  label = 'Contact Form';

  component = 'ContactForm';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    anchor,
    {
      name: 'formType',
      label: 'Form type (required)',
      help: 'The type of the form (required)',
      type: 'String',
      required: true,
      control: 'select',
      options: ['KINTO_GENERAL_FORM', 'KINTO_SHARE_FORM', 'KINTO_ONE_PERSONAL_FORM'],
      defaultValue: ''
    },
    formTitle,
    formDescription,
    formTitleSuccess,
    formContentSuccess,
    formTitleError,
    formContentError,
    spacingTop,
    spacingBottom
  ];

  properties = [];

  async adapter(contents: any[], extra: any, dataFetcher: any): Promise<object> {
    const locale = extra?.context?.locale;
    const productId = extra?.context?.productId;

    const rentalServicesByProductId = (await dataFetcher(Service.getRentalServiceProductIdForForm(), { productId: productId }))
      ?.data?.rentalServices;

    const rentalServices = rentalServicesByProductId || [];
    const dealers = [];
    const dealersByLocation = (await dataFetcher(Dealers.getAllDealersAddressForForm(), { productId: productId }))?.data
    ?.dealers;

    dealersByLocation.forEach((dealer) => {
      dealer.addresses.forEach((address) => {
        const isMainAddress = address.mainAddress;

        const dealerData = {
          id: dealer.id,
          address: { ...address },
          name: isMainAddress ? dealer.name : address.name,
          companyName: dealer.companyName,
          mainAddress: isMainAddress
        };

        dealers.push(dealerData);
      });
    });

    const getVehicleModels = (await dataFetcher(Vehicle.getVehiclesModels(), { locale: locale }))?.data?.vehicles;
    const vehicleModels = getVehicleModels || [];

    const listStates = [...new Set(dealers.map((state) => state.address.state))].sort();

    return {
      locale: String(locale).toLowerCase(),
      dealers: dealers ,
      rentalServices: rentalServices,
      vehicleModels: vehicleModels,
      states: listStates,
      productId: productId,
      title: extra?.settings?.title,
      formType: extra?.settings?.formType,
      description: extra?.settings?.description,
      titleSuccess: extra?.settings?.titleSuccess,
      contentSuccess: extra?.settings?.contentSuccess,
      titleError: extra?.settings?.titleError,
      contentError: extra?.settings?.contentError,
      spacingTop: extra?.settings?.spacingTop || '0',
      spacingBottom: extra?.settings?.spacingBottom || '0',
    };
  }
}

export { ContactForm };
