import React, { useEffect, useState } from 'react';
import TabCar from '../tab-car';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

// loading the sass style fot the component
import css from './styles.module.scss';

function TabCarCarousel(props) {
  const { className = '', content = [], service, locale, children, ...other } = props;
  const [activeTab, setActiveTab] = useState(null);
  const [slidesToShow, setSlidesToShow] = useState(1);
  const [slidesToScroll, setSlidesToScroll] = useState(1);

  useEffect(() => {
    if (content.length < 3) {
      setSlidesToShow(content.length);
      setSlidesToScroll(content.length);
    } else {
      setSlidesToShow(3);
      setSlidesToScroll(3);
    }
  }, [content]);

  const carouselSettings = {
    speed: 500,
    useTransform: false,
    dots: true,
    infinite: false,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    padding: 0,
    responsive: [
      {
        breakpoint: 1080, //$bp-tablet
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 680, //$bp-phone
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className={`${css['molecule__tab-car-carousel-container']} ${className}`} {...other}>
      <div className={css['slider-wrapper']}>
        <Slider {...carouselSettings} className={`${css['action-banner-wrapper']} ${className}`} {...other}>
          {content?.map((item, index) => {
            return (
              <div key={`${item.carlabel}-cont`} className={css['slide-container']}>
                <TabCar
                  onClick={() => handleTabClick(index)}
                  isActive={index === activeTab}
                  locale={locale}
                  service={service}
                  {...item}
                />
              </div>
            );
          })}
        </Slider>
        <div className={css['back-line']}></div>
      </div>
      {children}
    </div>
  );
}

export default TabCarCarousel;
