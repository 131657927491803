import { DateTimeFormatOptions } from 'next-intl';

export interface PageInterface {
  id: string;
  createdAt: DateTimeFormatOptions;
  title: string;
  slug: string;
  fullpath: string;
  description: string;
  sections?: object[];
  configs?: object[];
}

export interface ErrorInterface {
  message: string;
}

const findAllByLanguage = () => {
  return `
    query getPagesByLanguage($productId: String!) {
      pages (
        status: PUBLISHED,
        productId: $productId
      ) {
        id
        createdAt
        title
        slug
        fullpath
        status
        template
        description,
        tenant {
          locale
        }
      }
    }`;
};
const findBySlug = () => {
  return `
    query pageByPathAndProduct($fullpath: String!, $productId: String!) {
      pageByPathAndProduct(
        fullpath: $fullpath,
        productId: $productId
      ) {
        id
        title
        description
        slug
        fullpath
        status
        template
        createdAt
        configs {
          name
          value
        }
        tenant {
          locale
        }
        templateData
      }
    }`;
};

const findPageTree = () => {
  return `
    query pageTree($id: String!) {
      pageTree(id: $id) {
        id
        title
        fullpath
      }
    }`;
};

const findPageSectionsByPageId = () => {
  return `
    query sectionsByPage($pageId: String!) {
      sectionsByPage(pageId: $pageId) {
        id
        name
        description
        component
        componentData
        contents {
          id
          title
          icon {
            title
            url
          }
          subtitle
          abstract
          description
          media {
            id
            title
            description
            subtitle
            url
            mediaType {
              id
              title
            }
          }
          mediaMob {
            id
            title
            description
            subtitle
            url
            mediaType {
              id
              title
            }
          }
          callToActions {
            id
            label
            target
          }
        }
      }
    }`;
};

const Page = {
  findAllByLanguage,
  findBySlug,
  findPageSectionsByPageId,
  findPageTree
};

export default Page;
